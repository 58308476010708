const chipTextFieldStyle = theme => ({
  chip: {
    "height": "27px",
    "& .MuiChip-label": {
      paddingRight: "0"
    },
    "& .MuiChip-deleteIcon": {
      width: "20px"
    }
  },
  icon: {
    width: "20px"
  },
  iconButton: {
    padding: "0px"
  },
  textField: {
    width: "150px",
    height: "27px",
    fontSize: theme.lsyPalette.subtitle3
  },
  textFieldInput: {
    "fontSize": theme.lsyPalette.subtitle3,
    "fontWeight": "400",
    "height": "27px",
    "paddingRight": "8px",
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
      height: "27px"
    },
    "& .MuiOutlinedInput-input": {
      padding: "0px",
      height: "27px"
    },
    "& .MuiOutlinedInput-input::placeholder": {
      opacity: 1,
      fontWeight: "400"
    }
  }
});
  
export default chipTextFieldStyle;
  