import { isConfirmationWorkSessionFlow } from "_constants/admin/tenant.constants";

const hasTasks = currentMembership => {
  const isKeyRequired = currentMembership?.work_session_parameters?.key_required;
  
  return isConfirmationWorkSessionFlow(currentMembership) || isKeyRequired;
};

export const keyHelper = {
  hasTasks
};