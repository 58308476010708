const formSwitchStyle = theme => ({
  switchLabel: {
    marginLeft: theme.spacing(0),
    width: "100%",
    justifyContent: "space-between",
    color: "inherit",
    fontWeight: "inherit"
  }
});

export default formSwitchStyle;