import { useCallback, useContext, useEffect, useMemo, useReducer, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useCustomCompareMemo, useCustomCompareCallback } from "use-custom-compare";
import { isEmpty, isEqual } from "lodash";
import moment from "moment";
import PropTypes from "prop-types";

import { alertActions } from "_actions";
import { adminConstants } from "_constants/admin.constants";
import { keyStatusName } from "_constants/key.constants";
import { LsyAdminDataContext } from "_contexts/LsyAdminData/LsyAdminData";
import { compactObj, hasAbility, keyHelper, lsyRouter } from "_helpers";
import { getFormattedLockName } from "_helpers/lock";
import { useLsyHistory } from "_hooks";
import { genericReducer } from "_reducers/general.reducer";
import { keyService, lockService, userService } from "_services/lockstasy";
import { fetchErrors } from "_utils";

import {
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Skeleton,
  TablePagination,
  Tooltip,
  Typography
} from "@mui/material";

import GridItem from "components/Grid/GridItem";
import ErrorBoundary from "_components/ErrorBoundary";
import Placeholder from "_components/Helper/Placeholder";
import CustomModal from "_components/Modal/CustomModal";
import KeyLockWizard from "_containers/Lockstasy/KeyLockWizard";
import LsyTable2 from "_components/Table/LsyTable2";
import Badge from "_components/UI/Badge";

import {
  Add as AddIcon,
  Dialpad as DialpadIcon,
  FilterList as FilterListIcon,
  Person as PersonIcon,
  Refresh,
  VpnKey as VpnKeyIcon
} from "@mui/icons-material";
import SiteIcon from "assets/teleporte/SiteIcon";
import LockGroupIcon from "assets/teleporte/LockGroupIcon";

import { makeStyles } from "tss-react/mui";
import styles from "assets/jss/containers/widgets/keyWidgetStyle";

const useStyles = makeStyles()(styles);

const initialState = {
  lock_ids: [],
  status: "0"
};

function KeyWidget(props) {
  const { lock, org, type, user, refreshKeys, setRefreshKeys } = props;
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation("default");
  const history = useLsyHistory();
  const currentMembership = useSelector((state) => state.memberships.currentMembership);
  const lsyAdminDataContext = useContext(LsyAdminDataContext);
  const ability = lsyAdminDataContext.ability;
  const includeSites = hasAbility(currentMembership, "view", "lock_collections") && ability.can("read", "lock_collections");
  const isRegularUser = currentMembership.role_id === adminConstants.LSY_USER;
  const { handleSubmit, setValue, formState: { errors }, control, clearErrors, reset } = useForm();
  const filterForm = useForm();
  const hasTask = useMemo(() => keyHelper.hasTasks(currentMembership), [currentMembership]);

  const [wizard, setWizard] = useState({ type: "key", open: false, key: 0, fetchWidgetData: null });
  const [anchorEl, setAnchorEl] = useState(null);
  const [keyModal, setKeyModal] = useReducer(genericReducer,
    {
      open: false, data: {}, fetchWidgetData: null
    }
  );
  const setKeyModalOpen = (open) => setKeyModal({ open });
  const [state, setState] = useReducer(genericReducer,
    {
      data: [],
      orderBy: "updatedAt",
      direction: "desc",
      currentPage: 1,
      rowsPerPage: 8,
      totalData: 0,
      filterVariables: initialState,
      filterActive: false,
      filterOpen: false,
      errorModalOpen: false,
      errorDescription: null,
      loading: true
    }
  );
  const setErrorModalOpen = (errorModalOpen) => setState({ errorModalOpen });
  const setFilterOpen = (filterOpen) => setState({ filterOpen });
  const setSort = (orderBy, direction) => setState({ orderBy, direction });
  const setLoading = (loading) => setState({ loading });
  const openFilter = () => setFilterOpen(true);

  const getSortBy = useCallback(() => {
    const sortMap = {
      ...type === "user" && {lock: "locks.name", site: "sites.name"},
      ...type === "lock" && {user: "user.name"},
      validFrom: "start_date",
      validUntil: "end_date",
      issuedBy: "issuers.email",
      updatedAt: "updated_at"
    };

    return `${sortMap[state.orderBy]},${state.direction}`;
  }, [state.orderBy, state.direction, type]);

  const handleOpenCreateKeyMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const fetchLocks = async (searchInput) => {
    try {
      const result = await lockService.fetchLocks({ search_name: searchInput });
      return result.data.map(lock => lock ? { id: lock.id, name: lock.full_identifier } : null);
    } catch (e) {
      console.warn("Warning, failed to fetch locks", e);
      return [];
    }
  };

  const fetchUsersWithInput = useCallback(async (inputValue) => {
    try {
      const result = await userService.fetchUsers({page: 1, page_index: 10, search: inputValue});
      return result.data.map(user => user ?
        {
          id: user.membership_id,
          name: `${user.first_name} ${user.last_name} (${user.email})`
        } : null
      );
    } catch (e) {
      console.warn("Warning, failed to fetch locks with given input", e);
    }
  }, []);

  const getIcon = useCustomCompareCallback(v => {
    if (v.lock_group_id) {
      const navigateToGroup = () => {
        if (ability.can("read", "lock_groups")) {
          history.push(lsyRouter("lock_group", v.lock_group_id));
        }
      };
      return <Tooltip
        classes={{ tooltip: classes.tooltip }}
        title={v.lock_group.name}
      >
        <div className={classes.iconDiv}>
          <LockGroupIcon height="25px" width="25px" onClick={navigateToGroup} />
        </div>
      </Tooltip>;
    } else if (v.site_id) {
      return <SiteIcon width="25px" height="25px" />;
    } else if (v.fsu) {
      return <DialpadIcon width="25px" height="25px" />;
    } else {
      return <PersonIcon className={classes.invisIcon} />;
    }
  }, [classes, ability], (prevDeps, nextDeps) => isEqual(prevDeps, nextDeps));

  const getFormattedLoadingData = () => {
    const headers = [{ id: "type", label: <Skeleton/> }];
    const data = [...Array(4)].map(() => ({type: { text: <Skeleton/> }}));

    return [headers, data];
  };

  const getFormattedTableData = useCustomCompareCallback(data => {
    const headers = [
      { id: "type", label: t("widgetField.type"), classNameProps: classes.tableHeaderCell },
      ...type === "user" && includeSites ?
        [{ id: "site", label: t("widgetField.site"), sortable: true, classNameProps: classes.tableHeaderCell }] : [],
      ...type === "user" ?
        [{ id: "lock", label: t("widgetField.lock"), sortable: true, classNameProps: classes.tableHeaderCell }] : [],
      ...type === "lock" ?
        [{ id: "user", label: t("widgetField.user"), sortable: false, classNameProps: classes.tableHeaderCell }] : [],
      { id: "validFrom", label: t("label.validFrom"), sortable: true, classNameProps: classes.tableHeaderCell },
      { id: "validUntil", label: t("label.validUntil"), sortable: true, classNameProps: classes.tableHeaderCell },
      { id: "updatedAt", label: t("label.updatedAt"), sortable: true, classNameProps: classes.tableHeaderCell },
      { id: "issuedBy", label: t("label.issuedBy"), sortable: true, classNameProps: classes.tableHeaderCell },
      { id: "reference", label: t("label.reference"), sortable: false, classNameProps: classes.tableHeaderCell },
      ...hasTask ? [{ id: "tasks", label: t("label.tasks"), sortable: false, classNameProps: classes.tableHeaderCell }] : [],
      { id: "comments", label: t("label.comments"), sortable: false, classNameProps: classes.tableHeaderCell }
    ];

    const tableData = data.map((v) => {
      const navigateToSite = () => history.push(lsyRouter("site", v.site_id));
      return {
        type: {
          text: <div className={classes.typeIconWrap}>{getIcon(v)}</div>
        },
        ...type === "user" &&
          {...includeSites && {
            site: {
              text: v.site?.name || "",
              action: v.site_id ? navigateToSite : null,
              sortable: true,
              classNameProps: classes.tableBodyCell
            }},
          lock: {
            text: getFormattedLockName({name: v.lock_name, id: v.lock_id}),
            action: ability.can("read", "locks") && !isRegularUser ? () => {
              history.push(lsyRouter("lock", v.lock_id));
            } : null,
            classNameProps: classes.tableBodyCell
          }},
        ...type === "lock" &&
          {user: {
            text: v.user.name,
            action: ability.can("read", "users") && !isRegularUser ? () => {
              history.push(lsyRouter("user", v.user.membership_id));
            } : null
          }},
        validFrom: {
          text: moment(v.start_date).local().format("lll"),
          classNameProps: classes.tableBodyCell
        },
        validTill: {
          text: moment(v.end_date).local().format("lll"),
          key_status: v.active_status,
          classNameProps: classes.tableBodyCell
        },
        updatedAt: {
          text: moment(v.updated_at).local().format("lll"),
          classNameProps: classes.tableBodyCell
        },
        issuedBy: {
          text: v.issuer.name,
          classNameProps: classes.tableBodyCell
        },
        reference: {
          text: v.ticket_number,
          classNameProps: classes.tableBodyCell
        },
        ...hasTask && {
          tasks: {
            text: isEmpty(v.options?.tasks) ? "" : v.options.tasks.join(", "),
            classNameProps: classes.tableBodyCell
          }
        },
        comments: {
          text: v.comments,
          classNameProps: classes.tableBodyCell
        },
        rowAction: () => {
          setKeyModal({ open: true, data: v, type: v.lock_group_id ? "lockGroup" : v.site_id ? "site" : "regular" });
        }
      };
    });
    return [headers, tableData];
  }, [includeSites, classes, t, ability, isRegularUser, getIcon], (prevDeps, nextDeps) => isEqual(prevDeps, nextDeps));

  const fetchKeys = useCallback(async () => {
    const filterVars = { ...state.filterVariables };

    const options = {
      page: state.currentPage,
      page_size: state.rowsPerPage,
      sort_by: getSortBy(),
      ...filterVars,
      ...type === "user" && {
        membership_ids: `[${user.membership_id}]`,
        ...!isEmpty(filterVars.lock_ids) && { lock_ids: `[${filterVars.lock_ids.map(lock => lock.id)}]` }
      },
      ...type === "lock" && {
        lock_ids: `[${lock.id}]`,
        ...!isEmpty(filterVars.membership_ids) && { membership_ids: `[${filterVars.membership_ids.map(user => user.id)}]` }
      }
    };

    if (options.start_date) {
      options.start_date = options.start_date.startOf("day");
    }
    if (options.end_date) {
      options.end_date = options.end_date.endOf("day");
    }

    try {
      const result = await keyService.fetchKeys({ ...compactObj(options) });
      setState({
        data: getFormattedTableData(result?.data),
        totalData: result?.meta?.pagination?.total,
        loading: false
      });
    } catch (e) {
      const errors = fetchErrors(e);

      if(errors[0]?.detail === "error.invalid.start_date.gt.end_date") {
        dispatch(alertActions.send(t("error.invalidDates"), "error"));
      } else {
        dispatch(alertActions.send(t("error.fetchKeys"), "error"));
      }

      console.warn("Warning, failed to fetch keys", e);
      setState({ data: [], totalData: 0, loading: false });
    }
  }, [dispatch, lock, user, type, getSortBy, state.currentPage, state.filterVariables, state.rowsPerPage, getFormattedTableData, t]);

  useEffect(() => {
    if (!isEmpty(user) || !isEmpty(lock)) {
      fetchKeys();
    }
  }, [lock, user, fetchKeys]);

  useEffect(() => {
    if (refreshKeys) {
      fetchKeys();
      setRefreshKeys(false);
    }
  }, [fetchKeys, refreshKeys, setRefreshKeys]);

  const getFilterOptions = () => {
    const options = [
      {
        field: "status",
        type: "select",
        label: t("label.status"),
        defaultValue: "0",
        options: [
          ...Object.keys(keyStatusName).map(value => ({ name: t(keyStatusName[value]), value: value })),
          { name: t("label.all"), value: " " }
        ]
      },
      {
        field: "start_date",
        type: "datePicker",
        clearable: true,
        label: t("label.startDate"),
        defaultValue: null
      },
      {
        field: "end_date",
        type: "datePicker",
        clearable: true,
        label: t("label.endDate"),
        defaultValue: null
      }
    ];

    if (!isRegularUser) {
      if (type === "user") {
        options.unshift({
          field: "lock_ids",
          type: "typeAhead",
          autoFocus: true,
          defaultValue: [],
          placeholder: t("widgetField.lock"),
          async: true,
          isMulti: true,
          promiseOptions: fetchLocks,
          asyncProps: {defaultOptions: true}
        });
      } else if (type === "lock") {
        options.unshift({
          field: "membership_ids",
          type: "typeAhead",
          autoFocus: true,
          defaultValue: [],
          placeholder: t("label.searchUsers"),
          async: true,
          isMulti: true,
          promiseOptions: fetchUsersWithInput,
          asyncProps: {defaultOptions: true}
        });
      }
    }

    return options;
  };

  const resetFilter = () => {
    if (!isEqual(initialState, state.filterVariables)) {
      filterForm.reset(initialState);
      setState({
        filterVariables: initialState,
        currentPage: 1,
        filterActive: false
      });
    } else {
      setFilterOpen(false);
    }
  };

  const onFilterSubmit = (data) => {
    if (!isEqual(data, state.filterVariables)) {
      filterForm.reset(data);
      setState({
        filterVariables: {
          ...state.filterVariables,
          ...data
        },
        filterActive: true,
        filterOpen: false,
        currentPage: 1
      });
    } else {
      setFilterOpen(false);
    }
  };

  const canUpdateKey = () => {
    const preventKeyChanges = currentMembership.tenant_settings?.prevent_key_meta_changes || false;

    if (keyModal.data.semaphore_locked || preventKeyChanges) {
      return false;
    }

    if (keyModal.type === "regular") {
      return ability.can("update", "keys");
    } else if (keyModal.type === "site") {
      return ability.can("update", "lock_collection_keys");
    } else {
      return ability.can("update", "lock_group_keys");
    }
  };

  const form = useCustomCompareMemo(() => {
    const { data, type } = keyModal;
    if (type !== "lockGroup" && canUpdateKey()) {
      return [
        {
          field: "ticket_number",
          type: "textField",
          label: t("label.referenceNumber"),
          defaultValue: data?.ticket_number || "",
          autoFocus: true,
          placeholder: `${t("label.maxChars", { num: 50 })} [${t(
            "label.optional"
          ).toLowerCase()}]`,
          inputProps: {
            maxLength: 50
          }
        },
        {
          field: "comments",
          type: "textField",
          label: t("label.comments"),
          defaultValue: data?.comments || "",
          placeholder: `${t("label.maxChars", { num: 100 })} [${t(
            "label.optional"
          ).toLowerCase()}]`,
          inputProps: {
            maxLength: 100
          }
        }
      ];
    }

    return null;
  }, [keyModal], (prevDeps, nextDeps) => isEqual(prevDeps, nextDeps));

  const resetKeyDetailsFormFields = () => {
    if (form) {
      let formFields = {};
      for (const field of form) {
        formFields[field.field] = field.defaultValue;
      }

      reset(formFields);
    }
  };

  const keyDescription = useCustomCompareMemo(() => {
    const data = keyModal.data || {};
    return (<Grid className={classes.details} container direction="column" data-testid="keyDetailsSection">
      <GridItem xs={12} className={classes.keyValueItem} data-testid="validFrom">
        <span>{t("label.validFrom")}: </span>
        <span>{moment(data.start_date).format("lll")}</span>
      </GridItem>
      <GridItem xs={12} className={classes.keyValueItem} data-testid="validUntil">
        <span>{t("label.validUntil")}: </span>
        <span>{moment(data.end_date).format("lll")}</span>
      </GridItem>
      <GridItem xs={12} className={classes.keyValueItem} data-testid="user">
        <span>{t("widgetField.user")}: </span>
        <span>{`${data.user?.name} (${data.user?.email})`}</span>
      </GridItem>
      <GridItem xs={12} className={classes.keyValueItem} data-testid="lock">
        <span>{t("widgetField.lock")}: </span>
        <span>{data.lock_name}</span>
      </GridItem>
      {hasTask && keyModal.type !== "lockGroup" &&
        <GridItem xs={12} className={classes.keyValueItem} data-testid="tasks">
          <span>{t("label.tasks")}: </span>
          <span>{data.options?.tasks?.join(", ") || ""}</span>
        </GridItem>
      }
      {!canUpdateKey() && keyModal.type !== "lockGroup" &&
        <>
          <GridItem xs={12} className={classes.keyValueItem} data-testid="referenceNumber">
            <span>{t("label.referenceNumber")}: </span>
            <span>{data.ticket_number || ""}</span>
          </GridItem>
          <GridItem xs={12} className={classes.keyValueItem} data-testid="comments">
            <span>{t("label.comments")}: </span>
            <span>{data.comments || ""}</span>
          </GridItem>
        </>
      }
    </Grid>);
  }, [keyModal], (prevDeps, nextDeps) => isEqual(prevDeps, nextDeps));

  const deleteKey = async (id) => {
    setLoading(true);
    try {
      await keyService.deleteKey({ keyId: id });
      fetchKeys();
      dispatch(alertActions.send(t("success.deleteKey")));
    } catch (e) {
      console.warn("Warning, failed to delete key", e);
      dispatch(alertActions.send(t("error.deleteKey"), "error"));
      setLoading(false);
    }
  };

  const handleDeleteKey = () => {
    if (keyModal.type === "regular") {
      deleteKey(keyModal.data.id);
    } else {
      const name = keyModal.type === "site" ? keyModal.data.site?.name : keyModal.data.lock_group?.name;
      const link = keyModal.type === "site" ? lsyRouter("site", keyModal.data.site_id) : lsyRouter("lock_group", keyModal.data.lock_group_id);
      const instruction = keyModal.type === "site" ? "instructions.manageSiteKeys" : "instructions.manageLockGroupKeys";
      const error = keyModal.type === "site" ? "error.deleteKeyTypeSite" : "error.deleteKeyTypeLockGroup";

      const description = <div>
        <p className={classes.deleteKeyMsg}>{t(error, { name: name })}</p>
        <p>{t(instruction, { link: <span className={classes.actionLink} onClick={() => history.push(link)}>{t("label.here")}</span> })}</p>
      </div>;

      setState({
        errorModalOpen: true,
        errorDescription: description
      });
    }
  };

  const updateKey = async (data) => {
    setLoading(true);
    try {
      await keyService.updateKey({ id: keyModal.data.id, data: { key: data } });
      fetchKeys();
      dispatch(alertActions.send(t("success.updateKey")));
    } catch (e) {
      console.warn("Warning, failed to update key", e);
      dispatch(alertActions.send(t("error.updateKey"), "error"));
      setLoading(false);
    }
  };

  const onKeySubmit = (data) => {
    if (keyModal.type !== "lockGroup") {
      updateKey(data);
    }
    setKeyModal({ open: false });
  };

  const canDeleteKey = () => {
    if (keyModal.data.fsu || lock?.semaphore_locked || keyModal.data.semaphore_locked) {
      return false;
    }

    if (keyModal.type === "regular") {
      return ability.can("delete", "keys");
    } else if (keyModal.type === "site") {
      return ability.can("delete", "lock_collection_keys");
    } else {
      return ability.can("delete", "lock_group_keys");
    }
  };

  const handleCloseCreateKeyMenu = () => setAnchorEl(null);

  const handleSelectOptionCreateKey = (type) => {
    setAnchorEl(null);
    setWizard(prev => {
      return { ...prev, type: type, open: true, key: Math.random() };
    });
  };

  const handleSelectKey = () => handleSelectOptionCreateKey("key");
  const handleSelectSiteKey = () => handleSelectOptionCreateKey("userSiteKey");
  const handleSelectGroupKey = () => handleSelectOptionCreateKey("lockgroupKey");

  const handleKeyDown = (e, type) => {
    if (e.key === "Tab") {
      handleSelectOptionCreateKey(type);
    }
  };

  const handleKeyDownKey = (e) => handleKeyDown(e, "key");
  const handleKeyDownSiteKey = (e) => handleKeyDown(e, "userSiteKey");
  const handleKeyDownLockGroupKey = (e) => handleKeyDown(e, "lockgroupKey");

  const handleChangePage = (_, newPage) => {
    setState({ currentPage: newPage + 1, loading: true });
  };

  const openCreateKeyMenu = (e) => {
    if (type === "user") {
      handleOpenCreateKeyMenu(e);
      setWizard({ fetchWidgetData: fetchKeys });
    } else if (type === "lock") {
      setWizard(prev => {
        return { ...prev, fetchWidgetData: fetchKeys, type: "lockKey", open: true, key: Math.random() };
      });
    }
  };

  const renderTitleSection = () => {
    return <Grid item data-testid="titleSection">
      <Typography className={classes.title}><b>{t("label.keys")}</b></Typography>
    </Grid>;
  };

  const renderActionButtonsSection = () => {
    return <Grid item data-testid="actionButtonsSection">
      <ErrorBoundary>
        <Menu
          id="create-key-options-menu"
          data-testid="create-key-options-menu"
          anchororigin={{ vertical: "top", horizontal: "left" }}
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleCloseCreateKeyMenu}
          transformOrigin={{ vertical: "top", horizontal: "center" }}
        >
          {ability.can("create", "keys") &&
            <MenuItem onKeyDown={handleKeyDownKey} onClick={handleSelectKey}>{t("label.key.key")}</MenuItem>}
          {ability.can("create", "lock_collection_keys") &&
            <MenuItem onKeyDown={handleKeyDownSiteKey} onClick={handleSelectSiteKey}>{t("label.lockCollectionKey")}</MenuItem>}
          {ability.can("create", "lock_group_keys") &&
            <MenuItem onKeyDown={handleKeyDownLockGroupKey} onClick={handleSelectGroupKey}>{t("label.lockGroupKey")}</MenuItem>}
        </Menu>
        {ability.can("create", "keys") || ability.can("create", "lock_collection_keys") || ability.can("create", "lock_group_keys") ?
          <Tooltip
            classes={{ tooltip: classes.tooltip }} 
            title={type === "lock" && lock.semaphore_locked ? t("error.lock.locked") : t("button.issueKey")}
          >
            <span>
              <IconButton
                size="small"
                disabled={lock?.semaphore_locked}
                onClick={openCreateKeyMenu}
              >
                <AddIcon className={!lock?.semaphore_locked ? classes.icon : null} />
              </IconButton>
            </span>
          </Tooltip> : null
        }
        <Tooltip classes={{ tooltip: classes.tooltip }} title={state.filterActive ? t("label.filterApplied") : t("button.filter")}>
          <IconButton size="small" onClick={openFilter}>
            <Badge invisible={!state.filterActive}>
              <FilterListIcon className={classes.icon} />
            </Badge>
          </IconButton>
        </Tooltip>
        <Tooltip classes={{ tooltip: classes.tooltip }} title={t("button.refresh")}>
          <IconButton size="small" onClick={fetchKeys}>
            <Refresh className={classes.icon}/>
          </IconButton>
        </Tooltip>
      </ErrorBoundary>
    </Grid>;
  };

  const renderTable = () => {
    if (isEmpty(state.data[1])) {
      return <Placeholder icon={<VpnKeyIcon/>}>{t("fallbacks.noKeysFound")}</Placeholder>;
    }
    
    return <LsyTable2
      data={state.loading ? getFormattedLoadingData() : state.data}
      sort={setSort}
      orderBy={state.orderBy}
      sortDirection={state.direction}
    />;
  };

  const renderTableSection = () => {
    return <Grid item xs={12} data-testid="tableSection">
      <ErrorBoundary>
        { renderTable() }
        <TablePagination
          data-testid="pagination"
          component="div"
          count={state.totalData || 0}
          rowsPerPage={state.rowsPerPage}
          page={state.currentPage - 1}
          onPageChange={handleChangePage}
          rowsPerPageOptions={[]}
        />
      </ErrorBoundary>
    </Grid>;
  };

  const renderModalsSection = () => {
    return <>
      {wizard.open &&
        <KeyLockWizard
          key={wizard.key}
          open={wizard.open}
          setOpen={setWizard}
          fetchWidgetData={wizard.fetchWidgetData}
          org={org}
          type={wizard.type}
          {...type === "user" && {user: user}}
          {...type === "lock" && {lock: lock}}
        />}
      <CustomModal
        open={state.errorModalOpen}
        setOpen={setErrorModalOpen}
        title={t("label.error")}
        type="alert"
        description={state.errorDescription}
      />
      <form onSubmit={handleSubmit(onKeySubmit)}>
        <CustomModal
          open={keyModal.open}
          setOpen={setKeyModalOpen}
          onOpen={resetKeyDetailsFormFields}
          handleSubmit={handleSubmit(onKeySubmit)}
          title={t("label.keyDetails")}
          type="formCreator"
          modalStyle={classes.editKeyModal}
          description={keyDescription}
          handleCancel={handleDeleteKey}
          additionalClose={!canUpdateKey()}
          confirm={(keyModal.type !== "lockGroup" && canUpdateKey()) ? t("button.save") : null}
          cancel={t("button.deleteKey")}
          confirmDisabled={!canUpdateKey()}
          cancelDisabled={!canDeleteKey()}
          submit
          clearIcon
          manualClose
          errors={errors}
          clearErrors={clearErrors}
          control={control}
          setValue={setValue}
          formOptions={form}
        />
      </form>
      <CustomModal
        open={state.filterOpen}
        setOpen={setFilterOpen}
        handleSubmit={filterForm.handleSubmit(onFilterSubmit)}
        handleCancel={resetFilter}
        title={t("button.filter")}
        type="formCreator"
        confirm={t("button.apply")}
        cancel={t("button.reset")}
        manualClose
        formOptions={getFilterOptions()}
        errors={filterForm.formState.errors}
        clearErrors={filterForm.clearErrors}
        control={filterForm.control}
        setValue={filterForm.setValue}
        modalStyle={classes.filterModal}
        submit
      />
    </>;
  };

  return <Grid container alignItems="center" justifyContent="space-between">
    { renderTitleSection() }
    { renderActionButtonsSection() }
    { renderTableSection() }
    { renderModalsSection() }
  </Grid>;
}

KeyWidget.defaultProps = {
  setRefreshKeys: () => {}
};

KeyWidget.propTypes = {
  lock: PropTypes.object,
  org: PropTypes.string,
  refreshKeys: PropTypes.bool,
  setRefreshKeys: PropTypes.func,
  type: PropTypes.string,
  user: PropTypes.object
};

export default KeyWidget;