import { useCallback, useEffect, useReducer } from "react";
import PropTypes from "prop-types";
import { isEmpty } from "lodash";
import { useTranslation } from "react-i18next";

import { isNullOrUndefined } from "_helpers";
import { genericReducer } from "_reducers/general.reducer";

import {
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  Skeleton,
  Typography
} from "@mui/material";

import ErrorBoundary from "_components/ErrorBoundary";
import Placeholder from "_components/Helper/Placeholder";

import {
  List as ListIcon
} from "@mui/icons-material";

import { makeStyles } from "tss-react/mui";
import styles from "assets/jss/containers/widgets/workSessionTasksStyle";

const useStyles = makeStyles()(styles);

function WorkSessionTasks(props) {
  const { selectedWorkSession } = props;

  const { classes } = useStyles();
  const { t } = useTranslation("default");

  const [state, setState] = useReducer(genericReducer,
    {
      tasks: [],
      selectedTasks: [],
      completedTasks: [],
      isLoading: true
    }
  );

  const formatData = useCallback(() => {
    setState({
      tasks: selectedWorkSession.options?.tasks || [],
      selectedTasks: selectedWorkSession.options?.chosen_tasks || [],
      completedTasks: selectedWorkSession.options?.completed_tasks || [],
      isLoading: false
    });
  }, [selectedWorkSession]);

  useEffect(() => {
    if (isNullOrUndefined(selectedWorkSession)) {
      return;
    }

    formatData();
  }, [selectedWorkSession, formatData]);

  const renderLoading = () => {
    return <Grid container direction="column">
      {[...Array(4).keys()].map(v => {
        return <Grid item key={v}>
          <Skeleton variant="text" height={40} data-testid="skeleton"/>
        </Grid>;
      })}
    </Grid>;
  };

  const renderPlaceholder = () => {
    return <Placeholder
      message={t("fallbacks.noWorkSessionTasks")}
      icon={<ListIcon/>}
      classNameMessage={classes.placeholderText}
      classNameIcon={classes.placeholderIcon}
    />;
  };
  
  const renderTaskList = (title, tasks) => {
    return <Grid item xs={12} md={4} data-testid={"taskList"}>
      <Typography className={classes.subtitle} data-testid={`subtitle-${title}`}>{title}</Typography>
      <Divider data-testid={`divider-${title}`}/>
      {!isEmpty(tasks) &&
        <List dense data-testid={`list-${title}`}>
          {tasks.map(task => (
            <ListItem key={task} className={classes.listItem} data-testid={`listItem-${task}`}>
              <ListItemText primary={task}/>
            </ListItem>
          ))}
        </List>
      }
    </Grid>;
  };

  const renderWorkSessionTasks = () => {
    if (state.isLoading) {
      return renderLoading();
    }

    if (isEmpty(state.tasks) && isEmpty(state.selectedTasks) && isEmpty(state.completedTasks)) {
      return renderPlaceholder();
    }
  
    return <Grid container alignItems="flex-start" justifyContent="center" spacing={1}>
      { renderTaskList(t("label.all"), state.tasks) }
      { renderTaskList(t("label.selected"), state.selectedTasks) }
      { renderTaskList(t("label.completed"), state.completedTasks) }
    </Grid>;
  };
  
  return <ErrorBoundary>
    <Grid container direction="column" spacing={1}>
      <Grid item data-testid="headerSection">
        <Typography variant="body1"><b>{t("label.tasks")}</b></Typography>
      </Grid>
      <Grid item data-testid="bodySection">
        { renderWorkSessionTasks() }
      </Grid>
    </Grid>
  </ErrorBoundary>;
}

WorkSessionTasks.propTypes = {
  selectedWorkSession: PropTypes.object
};

export default WorkSessionTasks;