import { PropTypes } from "prop-types";
import { makeStyles } from "tss-react/mui";

import styles from "assets/jss/components/ksc1Style";

const useStyles = makeStyles()(styles);

function KSC1(props) {
  const { classes, cx } = useStyles();

  const pathClassName = cx(
    classes.sfic,
    (props.pathClassName || ""),
    {[classes.disabled]: props.disabled}
  );

  return <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 220 255" width={props.width} height={props.height} className={props.className}>
    <path className={cx(pathClassName, classes.externalLine)} d="M102.73,3.6c-2.54,24.7-7.95,28.58-11,29.05C40.2,40.42,2.5,84.9,2.5,138.6c0,59.18,47.98,107.16,107.16,107.16s107.16-47.98,107.16-107.16c0-52.49-36.26-96.17-86.09-105.37-3.32-.61-9.91-4.11-13-29.63-.18-1.45-14.85-1.47-15,0Z"/>
    <circle className={pathClassName} cx="109.66" cy="138.6" r="93.32"/>
    <g id="Layer_2">
      <path className={pathClassName} d="M85.63,138.6v-1.19c-6.84-6.33-11.13-13.68-11.13-23.73,0-19.12,15.5-34.62,34.63-34.62s34.62,15.5,34.62,34.63c0,9.52-3.84,17.47-10.06,23.73v1.19s0,.81,0,.81c6.84,6.33,11.13,14.05,11.13,24.1,0,19.12-15.5,34.62-34.63,34.62s-34.62-15.5-34.62-34.63c0-9.52,3.84-17.84,10.06-24.1v-.81Z"/>
    </g>
  </svg>;
}

KSC1.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  className: PropTypes.string,
  pathClassName: PropTypes.string,
  disabled: PropTypes.bool
};

export default KSC1;