const selectExportStyle = (theme) => ({
  chip: {
    "padding": "0",
    "height": "27px",
    "& .MuiChip-deleteIcon": {
      width: "20px"
    },
    "& input": {
      width: "150px"
    }
  },
  chipIcon: {
    margin: "0",
    width: "20px",
    height: "auto",
    paddingRight: theme.spacing(0.75)
  },
  clickable: {
    cursor: "pointer"
  },
  container: {
    "display": "flex",
    "alignItems": "center",
    "& .MuiFormControl-root": {
      width: "fit-content"
    }
  },
  select: {
    "fontSize": theme.typography.xsFontSize,
    "fontWeight": theme.lsyPalette.lightWeight,
    "width": "fit-content",
    "& .MuiSvgIcon-root": {
      display: "none"
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "0"
    },
    "& .MuiSelect-select": {
      padding: "0"
    }
  },
  selectHiddenOption: {
    display: "none"
  },
  selectOptions: {
    fontSize: theme.typography.xsFontSize
  }
});

export default selectExportStyle;