import { combineReducers } from "redux";

import auth from "./authentication.reducer";
import memberships from "./membership.reducer";
import locale from "./locale.reducer";
import redirect from "./redirect.reducer";
import modal from "./modal.reducer";
import { alert as alerts } from "./alert.reducer";
import organizations from "./organization.reducer";
import mobile from "./mobile.reducer";
import * as actionTypes from "_actions/actionTypes";

const appReducer = combineReducers({
  locale,
  auth,
  alerts,
  memberships,
  redirect,
  modal,
  organizations,
  mobile
});

const rootReducer = (state, action) => {
  if (action.type === actionTypes.AUTH_LOGOUT) {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
