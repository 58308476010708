export const LANGUAGE_SET = "SET_LANGUAGE";

export const AUTH_START = "AUTH_START";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_FAIL = "AUTH_FAIL";
export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const AUTH_TC_STEP = "AUTH_TC_STEP";
export const AUTH_MFA_STEP = "AUTH_MFA_STEP";
export const AUTH_MFA_SETUP = "AUTH_MFA_SETUP";
export const AUTH_MFA_RESET = "AUTH_MFA_RESET";
export const AUTH_UPDATE = "AUTH_UPDATE";

export const PASS_RESET_START = "PASS_RESET_START";
export const PASS_RESET_SUCCESS = "PASS_RESET_SUCCESS";
export const PASS_RESET_FAIL = "PASS_RESET_FAIL";

export const MEMBERSHIPS_SET = "SET_MEMBERSHIPS";
export const MEMBERSHIPS_START = "MEMBERSHIPS_START";
export const MEMBERSHIPS_FAIL = "MEMBERSHIPS_FAIL";
export const MEMBERSHIP_SET_CURRENT = "SET_CURRENT_MEMBERSHIP";
export const MEMBERSHIP_SET_CURRENT_SETTINGS = "SET_CURRENT_SETTINGS_MEMBERSHIP";
export const MEMBERSHIP_REMOVED_CURRENT = "REMOVED_CURRENT_MEMBERHIP";
export const MEMBERSHIP_UPDATE = "MEMBERSHIP_UPDATE";

export const MOBILE_SET = "SET_MOBILE";

export const GLOBAL_SET_ORGANIZATIONS = "GLOBAL_SET_ORGANIZATIONS";
export const GLOBAL_ORGANIZATIONS_START = "GLOBAL_ORGANIZATIONS_START";
export const GLOBAL_ORGANIZATIONS_FAIL = "GLOBAL_ORGANIZATIONS_FAIL";

export const GLOBAL_SET_CRYPTOS = "GLOBAL_SET_CRYPTOS";
export const GLOBAL_SET_SERVERS = "GLOBAL_SET_SERVERS";

export const REDIRECTION_SET_TARGET = "SET_REDIRECTION_TARGET";

export const SET_AUTH_REDIRECT_PATH = "SET_AUTH_REDIRECT_PATH";

export const SERA4_MODE = "SERA4_MODE";