import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { useLsyHistory } from "_hooks";

import {
  Box,
  Grid,
  Typography
} from "@mui/material";

import LsyFrame from "_components/Lockstasy/LsyFrame";
import PresenceDetectionWidget from "_containers/Widgets/PresenceDetectionWidget";

import { KeyboardBackspaceRounded } from "@mui/icons-material";

import { makeStyles } from "tss-react/mui";
import styles from "assets/jss/views/lockstasy/presenceDetectionStyle";

const useStyles = makeStyles()(styles);

function PresenceDetection() {
  const { t } = useTranslation("default");
  const { classes } = useStyles();
  const history = useLsyHistory();
  const id = useParams().id;
  const sera4Mode = useSelector((state) => state.auth.sera4Mode);
  const canReadPresenceDetection = useMemo(() => sera4Mode, [sera4Mode]);
  const type = history.location.pathname.includes("users") ? "user" : "lock";

  useEffect(() => {
    if (!canReadPresenceDetection) {
      history.goBack();
    }
  }, [canReadPresenceDetection, history]);

  const renderBackButton = () => {
    const histState = history.location?.state || {};

    return <Box className={classes.line} onClick={history.goBack}>
      <KeyboardBackspaceRounded className={classes.backIcon}/>
      <span className={classes.titleDescription}>
        {histState.user || histState.lock || t(`widgetField.${type}`)}
      </span>
    </Box>;
  };

  return <LsyFrame>
    {canReadPresenceDetection &&
      <Grid container alignItems="center" justifyContent="space-between" spacing={3}>
        <Grid item xs={12}>
          <Typography><b>{t("features.presenceDetection")}</b></Typography>
          {renderBackButton()}
        </Grid>
        <Grid item xs={12}>
          <PresenceDetectionWidget id={id} type={type} />
        </Grid>
      </Grid>
    }
  </LsyFrame>;
}

export default PresenceDetection;