const keyLockWizardStyle = theme => ({
  root: {
    marginTop: theme.spacing(4),
    width: "100%"
  },
  button: {
    marginTop: theme.spacing(3),
    marginRight: theme.spacing(1)
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  modal: {
    width: "900px",
    [theme.breakpoints.down("lg")]: {
      width: "100%",
      height: "100%"
    }
  },
  key: {
    fontSize: theme.lsyPalette.body2
  },
  value: {
    fontWeight: "800",
    fontSize: theme.lsyPalette.body2
  },
  keyValueItem: {
    marginBottom: "5px"
  },
  selectionItem: {
    marginTop: "3px",
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  summaryItem: {
  },
  selectionPageDiv: {
    position: "relative",
    display: "flex",
    alignItems: "center"
  },
  removeItem: {
    color: theme.lsyPalette.primary.main,
    position: "absolute",
    right: "0px",
    padding: "0px"
  },
  confirmSelection: {
    border: `1px solid ${theme.lsyPalette.secondary.background}`,
    overflow: "auto",
    height: "150px",
    borderRadius: "5px"
  },
  icon: {
    "color": theme.lsyPalette.primary.main,
    "&.Mui-active": {
      color: theme.lsyPalette.indicator
    },
    "&.Mui-completed": {
      color: theme.lsyPalette.indicator
    }
  },
  activeIcon: {}, //intentional
  completedIcon: {},
  stepButtons: {
    float: "right"
  },
  toggleDiv: {
    display: "flex",
    alignItems: "center",
    marginTop: "15px"
  },
  toggleSwitchLabel: {
    marginRight: "10px"
  },
  content: {
    paddingBottom: theme.spacing(1)
  },
  circularProgress: {
    marginRight: theme.spacing(1)
  },
  listLockIds: {
    paddingRight: "0px"
  }
});

export default keyLockWizardStyle;