const presenceDetectionStyle = (theme) => ({
  backIcon: {
    color: "inherit",
    fontSize: theme.lsyPalette.body1
  },
  line: {
    "width": "fit-content",
    "display": "flex",
    "alignItems": "center",
    "color": theme.lsyPalette.primary.mainLight,
    "cursor": "pointer",
    "&:hover": {
      color: theme.lsyPalette.primary.mainDark
    }
  },
  titleDescription: {
    color: "inherit",
    fontSize: theme.lsyPalette.body1
  }
});
  
export default presenceDetectionStyle;