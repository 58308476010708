import PropTypes from "prop-types";
import { Controller } from "react-hook-form";

import {
  FormControl,
  InputLabel,
  Select
} from "@mui/material";

const FormSelect = (props) => {
  const { control, name, label, defaultValue, children } = props;
  const labelId = `${name}-label`;

  return (
    <FormControl fullWidth {...props}>
      <InputLabel id={labelId}>{label}</InputLabel>
      <Controller
        render={({ field }) => (
          <Select {...field} data-testid={`select-${name}`} labelId={labelId} label={label}>
            {children}
          </Select>
        )}
        name={name}
        control={control}
        defaultValue={defaultValue}
      />
    </FormControl>
  );
};

FormSelect.propTypes = {
  children: PropTypes.node.isRequired,
  control: PropTypes.object.isRequired,
  defaultValue: PropTypes.string,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired
};

export default FormSelect;