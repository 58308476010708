import { isAutomaticWorkSessionFlow } from "_constants/admin/tenant.constants";
import { WORK_SESSION_STATUS, WORK_SESSION_CONFIRMATION_STATUS } from "_constants/workSession.constants";

const getStatuses = (currentMembership, t) => {
  const statuses = isAutomaticWorkSessionFlow(currentMembership) ? WORK_SESSION_STATUS : WORK_SESSION_CONFIRMATION_STATUS;
  
  return Object.keys(statuses).map(value => {
    return { name: t(statuses[value]), value: parseInt(value) };
  });
};

const getStatusName = (currentMembership, t, status) => {
  const statuses = isAutomaticWorkSessionFlow(currentMembership) ? WORK_SESSION_STATUS : WORK_SESSION_CONFIRMATION_STATUS;

  return t(statuses[status]);
};

export const workSessionHelper = {
  getStatuses,
  getStatusName
};