const appUpdateIconStyle = theme => ({
  container: {
    display: "flex",
    alignItems: "center"
  },
  icon: {
    fontSize: "20px"
  },
  message: {
    fontSize: theme.lsyPalette.subtitle3,
    color: theme.lsyPalette.primary.mainLight
  }
});

export default appUpdateIconStyle;
