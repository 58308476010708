import { memo } from "react";
import { PropTypes } from "prop-types";
import { isEmpty } from "lodash";

import { useTranslation } from "react-i18next";
import Tooltip from "@mui/material/Tooltip";
import AP3 from "./AP3";
import SLC2 from "./SLC2";
import AX5 from "./AX5";
import HSP1 from "./HSP1";
import KSC1 from "./KSC1";
import BrokenImageOutlinedIcon from "@mui/icons-material/BrokenImageOutlined";
import firmwareUpdateIcon from "assets/img/firmware-update-icon.png";

// Styles
import { makeStyles } from "tss-react/mui";
import styles from "assets/jss/components/lockIconsStyle.js";

const iconSizes = Object.freeze({
  xs: {
    width: "15px",
    height: "15px"
  },
  sm: {
    width: "25px",
    height: "25px"
  },
  md: {
    width: "50px",
    height: "50px"
  },
  lg: {
    width: "75px",
    height: "75px"
  },
  xl: {
    width: "100px",
    height: "100px"
  }
});

const useStyles = makeStyles()(styles);

function LockIcons(props) {
  const { classes, cx } = useStyles();
  const { t } = useTranslation("default");
  const { type, className, pathClassName, defaultStyle, hasFirmwareUpgrade, disabled } = props;
  const kscHeight = isEmpty(props.height) ? props.height : `${parseInt(props.height.replace("px", "")) - 10}px`;

  const getLockWithFirmwareIcon = (type) => {
    switch (true) {
      case /AP3/.test(type):
        return <div className={classes.lockWithUpdateWrap}>
          <AP3 className={cx(className, classes.relativePositioning)} pathClassName={cx(pathClassName, {[classes.disabled]: disabled})} width={props.width} height={props.height} /> 
          <img src={firmwareUpdateIcon} className={cx(classes.firmwareIcon, classes.firmwareIconAP3)} alt={t("label.firmwareUpgradeAvailable")} width={iconSizes[props.firmwareIconSize].width} height={iconSizes[props.firmwareIconSize].height} />
        </div>;
      case /AX5/.test(type) || /MX[45]/.test(type):
        return <div className={classes.lockWithUpdateWrap}>
          <AX5 className={cx(className, classes.relativePositioning)} pathClassName={cx(pathClassName, {[classes.disabled]: disabled})} width={props.width} height={props.height} />
          <img src={firmwareUpdateIcon} className={cx(classes.firmwareIcon, classes.firmwareIconAX5)} alt={t("label.firmwareUpgradeAvailable")} width={iconSizes[props.firmwareIconSize].width} height={iconSizes[props.firmwareIconSize].height} />
        </div>;
      case /SLC/.test(type):
        return <div className={classes.lockWithUpdateWrap}>
          <SLC2 className={cx(className, classes.relativePositioning)} pathClassName={cx(pathClassName, {[classes.disabled]: disabled})} width={props.width} height={props.height} />
          <img src={firmwareUpdateIcon} className={cx(classes.firmwareIcon, classes.firmwareIconSLC)} alt={t("label.firmwareUpgradeAvailable")} width={iconSizes[props.firmwareIconSize].width} height={iconSizes[props.firmwareIconSize].height} />
        </div>;
      case /HSP/.test(type):
        return <div className={classes.lockWithUpdateWrap}>
          <HSP1 className={cx(className, classes.relativePositioning)} pathClassName={cx(pathClassName)} width={props.width} height={props.height} disabled={disabled}/>
          <img src={firmwareUpdateIcon} className={cx(classes.firmwareIcon, classes.firmwareIconSLC)} alt={t("label.firmwareUpgradeAvailable")} width={iconSizes[props.firmwareIconSize].width} height={iconSizes[props.firmwareIconSize].height} />
        </div>;
      case /KSC1/.test(type):
        return <div className={classes.lockWithUpdateWrap}>
          <KSC1 className={cx(className, classes.relativePositioning)} pathClassName={cx(pathClassName)} width={props.width} height={kscHeight} disabled={disabled}/>
          <img src={firmwareUpdateIcon} className={cx(classes.firmwareIcon, classes.firmwareIconSLC)} alt={t("label.firmwareUpgradeAvailable")} width={iconSizes[props.firmwareIconSize].width} height={iconSizes[props.firmwareIconSize].height} />
        </div>;
      default:
        return <BrokenImageOutlinedIcon className={cx(className, (defaultStyle || ""))} color={disabled ? "disabled" : ""}/>;
    }
  };

  if(hasFirmwareUpgrade) {
    return <Tooltip
      classes={{ tooltip: classes.tooltip }}
      title={t("label.firmwareUpgradeAvailable")}
      placement="bottom"
    >
      {getLockWithFirmwareIcon(type)}
    </Tooltip>;
  } else {
    switch (true) {
      case /AP3/.test(type):
        return <AP3 className={className} pathClassName={cx(pathClassName, {[classes.disabled]: disabled})} width={props.width} height={props.height}/>;
      case /AX5/.test(type) || /MX[45]/.test(type):
        return <AX5 className={className} pathClassName={cx(pathClassName, {[classes.disabled]: disabled})} width={props.width} height={props.height}/>;
      case /SLC/.test(type):
        return <SLC2 className={className} pathClassName={cx(pathClassName, {[classes.disabled]: disabled})} width={props.width} height={props.height}/>;
      case /HSP/.test(type):
        return <HSP1 className={className} pathClassName={cx(pathClassName)} width={props.width} height={props.height} disabled={disabled}/>;
      case /KSC1/.test(type):
        return <KSC1 className={className} pathClassName={cx(pathClassName)} width={props.width} height={kscHeight} disabled={disabled}/>;
      default:
        return <BrokenImageOutlinedIcon className={cx(className, (defaultStyle || ""))} color={disabled ? "disabled" : ""}/>;
    }
  }
}

LockIcons.defaultProps = {
  firmwareIconSize: "sm",
  hasFirmwareUpgrade: false,
  disabled: false
};

LockIcons.propTypes = {
  type: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  className: PropTypes.string,
  pathClassName: PropTypes.string,
  hasFirmwareUpgrade: PropTypes.bool,
  firmwareIconSize: PropTypes.string,
  defaultStyle: PropTypes.string,
  disabled: PropTypes.bool
};

export default memo(LockIcons);
