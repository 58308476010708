import { forEach, flatten, intersection, uniqWith, isEqual, isEmpty } from "lodash";
import { compactObj } from "_helpers/utility";

export const MAX_LOCKS_QUANTITY_FILTER = 500;

export const LOCK_EVENT = Object.freeze({
  open: 0,
  closed: 1,
  note: 2
});

export const OPENED_VIA = Object.freeze({
  teleporte: 1,
  manualUnlock: 2,
  accessCode: 3,
  latchSensor: 4,
  doorSensor: 5,
  rts: 6,
  withoutTeleporte: 7,
  mfa:8,
  pirSensor: 9,
  vap: 10
});

export const LOCK_STATUS = Object.freeze({
  active: 0,
  deactivated: 1,
  all: 2
});

const getRangeOptions = (min, max) => {
  const options = [];
  for (let i = min; i <= max; ++i) {
    options.push({ id: i, name: i });
  }
  return options;
};

/* search objects and return the name field */
const fetchByObjectId = (obj, id, field = "name") => {
  let returnVal = null;

  forEach(obj, (value) => {
    if (parseInt(id) === value.id) {
      returnVal = value[field];
      return false;
    }
  });

  return returnVal;
};

const fetchById = (obj, id) => {
  let returnVal = null;

  forEach(obj, (value, key) => {
    if (parseInt(id) === value) {
      returnVal = key;
      return false;
    }
  });

  return returnVal;
};

export const lockLicenseConstants = Object.freeze({
  "BASIC": 1,
  "STANDARD": 2,
  "ENTERPRISE": 3,
  "ACAAS": 4,
  "STANDARD-POC": 5,
  "STANDARD-DEMO": 6,
  "ENTERPRISE-POC": 7,
  "ENTERPRISE-DEMO": 8,
  "FLEX": 9,
  "KEYLESS": 10,
  "KEYLESS-POC": 11,
  "KEYLESS-DEMO": 12
});

export const lockLicenseTranslationKeys = Object.freeze({
  "BASIC": "lock.license.basic",
  "STANDARD": "lock.license.standard",
  "ENTERPRISE": "lock.license.enterprise",
  "ACAAS": "lock.license.acaas",
  "STANDARD-POC": "lock.license.standard-poc",
  "STANDARD-DEMO": "lock.license.standard-demo",
  "ENTERPRISE-POC": "lock.license.enterprise-poc",
  "ENTERPRISE-DEMO": "lock.license.enterprise-demo",
  "FLEX": "lock.license.flex",
  "KEYLESS": "lock.license.keyless",
  "KEYLESS-POC": "lock.license.keyless-poc",
  "KEYLESS-DEMO": "lock.license.keyless-demo"
});

export const licensesNotRequiringInvoices = [
  lockLicenseConstants["BASIC"],
  lockLicenseConstants["STANDARD"],
  lockLicenseConstants["STANDARD-POC"],
  lockLicenseConstants["STANDARD-DEMO"],
  lockLicenseConstants["ENTERPRISE-DEMO"],
  lockLicenseConstants["ENTERPRISE-POC"],
  lockLicenseConstants["KEYLESS-POC"],
  lockLicenseConstants["KEYLESS-DEMO"]
];

export const lockLicenseConstantsById = (integer) => {
  return fetchById(lockLicenseConstants, integer);
};

export const lockSemaphoreConstants = Object.freeze({
  RELEASED: 0, // Use 0 for released because we might add more types.
  ACQUIRED: 1, // Start at 1 for any other types.
  ERROR: 2
});

export const lockSemaphoreConstantsById = (integer) => {
  return fetchById(lockSemaphoreConstants, integer);
};

export const LockTypeOptions = [
  { id: 0x0, name: "Prototype" },
  { id: 0x1, name: "Padlock" },
  { id: 0x2, name: "Enclosure" },
  { id: 0x3, name: "Invalid (3)" },
  { id: 0x4, name: "Actuator" },
  { id: 0x5, name: "Motorized" },
  { id: 0x6, name: "Pucklock" },
  { id: 0x7, name: "Enclosure Backup" },
  { id: 0x8, name: "Actuator Backup" },
  { id: 0x9, name: "SFIC" },
  { id: 0xA, name: "Handle" }
];

export const lockConstantsById = (value) => {
  return fetchByObjectId(LockTypeOptions, value);
};

export const lockTypeById = (id) => {
  for (let i = 0; i < LockTypeOptions.length; i++) {
    if (LockTypeOptions[i].id === id) {
      return LockTypeOptions[i].name;
    }
  }
  return null;
};

export const HardwareTypeOptions = [
  { id: 0x1, name: "AP3v1", hasBattery: true, hasShackle: true },
  { id: 0x5, name: "AP3v5", hasBattery: true, hasShackle: true },
  { id: 0xA, name: "AX5-XXX" },
  { id: 0x8, name: "AX5v2" },
  { id: 0x9, name: "AX5v2_5" },
  { id: 0xC, name: "AX5v3" },
  { id: 0xFE, name: "DKIT" },
  { id: 0x10, name: "HSP1v4", hasBattery: true  },
  { id: 0x11, name: "HSP1v5", hasBattery: true  },
  { id: 0x13, name: "HSP1v7", hasBattery: true  },
  { id: 0x14, name: "KSC1v1", hasBattery: true },
  { id: 0x4, name: "MX4" },
  { id: 0x2, name: "MX5v1" },
  { id: 0x3, name: "MX5v2" },
  { id: 0xD, name: "MX5v3" },
  { id: 0xFD, name: "Prototype" },
  { id: 0x7, name: "RMC8" },
  { id: 0x6, name: "SLC2v10", hasBattery: true  },
  { id: 0xE, name: "SLC2v11", hasBattery: true  },
  { id: 0xF, name: "SLC2v13", hasBattery: true  },
  { id: 0x12, name: "SLC2v16", hasBattery: true  },
  { id: 0x15, name: "TLC2v3" }
];

export const hardwareTypeByCode = (code) => {
  for (let i = 0; i < HardwareTypeOptions.length; i++) {
    if (HardwareTypeOptions[i].id === code) {
      return HardwareTypeOptions[i].name;
    }
  }
  return null;
};

export const hasBattery = (hardwareTypeId) => {
  const hardwareType = HardwareTypeOptions.find(hwTO => hwTO.id === hardwareTypeId);
  return hardwareType && hardwareType.hasBattery;
};

export const hasShackle = (hardwareTypeId) => {
  const hardwareType = HardwareTypeOptions.find(hwTO => hwTO.id === hardwareTypeId);
  return hardwareType && hardwareType.hasShackle;
};

// How do we display these when we want to visually filter them
export const HardwareTypeOptionMenu = [
  { label: "AP3", name: "AP3", value: 1 },
  { label: "AP3", name: "AP3", value: 5 },
  { label: "AX5", name: "AX5", value: 8 },
  { label: "AX5", name: "AX5", value: 9 },
  { label: "AX5", name: "AX5", value: 10 },
  { label: "AX5", name: "AX5", value: 12 },
  { label: "KSC1", name: "KSC1", value: 20 },
  { label: "MX4", name: "MX4", value: 4 },
  { label: "MX5", name: "MX5", value: 2 },
  { label: "MX5", name: "MX5", value: 3 },
  { label: "MX5", name: "MX5", value: 13 },
  { label: "RMC8", name: "RMC8", value: 7 },
  { label: "SLC2", name: "SLC2", value: 6 },
  { label: "SLC2", name: "SLC2", value: 14 },
  { label: "SLC2", name: "SLC2", value: 15 },
  { label: "SLC2", name: "SLC2", value: 18 },
  { label: "TLC2", name: "TLC2", value: 21 },
  { label: "HSP1", name: "HSP1", value: 16 },
  { label: "HSP1", name: "HSP1", value: 17 },
  { label: "HSP1", name: "HSP1", value: 19 },
  { label: "Unspecified Hardware Prototype", name: "Prototype", value: 253 },
  { label: "Development Board", name: "Development Board", value: 254 }
];

export const ComponentOptions = [
  { name: "NRF91 APP", value: "nrf91-app", fileName: "ota_app" },
  { name: "NRF91 BL", value: "nrf91-bl", fileName: "ota_bl" },
  { name: "NRF52 APP", value: "nrf52-app", fileName: "app" },
  { name: "NRF52 BL", value: "nrf52-bl", fileName: "bl" },
  { name: "NRF52 SD", value: "nrf52-sd", fileName: "sd" }
];

export const getComponentOptionByFileName = (name) => {
  const components = ComponentOptions.filter(component => component.fileName === name);
  return isEmpty(components) ? {} : components[0];
};

export const getHardwareTypeName = (id) => {
  const hw = HardwareTypeOptionMenu.find(hw => hw.value === id) || { name: "unknown" };
  return hw.name;
};

// return a list of hardware options based on provided hardware ids
export const FilteredHardwareTypeOptionMenu = (ids) => {
  const results = HardwareTypeOptionMenu.map((el) => {
    if (Array.isArray(el.value)) {
      if (intersection(el.value, ids).length > 0) {
        return { name: el.label, value: el.name };
      }
    }

    if (ids.includes(el.value))
      return { name: el.label, value: el.name };

  });

  return uniqWith(results.filter(el => el != undefined), isEqual);
};

export const ControllerIDs = compactObj(flatten(HardwareTypeOptionMenu.map((el) => {
  if (["AX5", "MX4", "MX5", "RMC8", "SLC2", "HSP1"].includes(el.name)) {
    return el.value;
  } else {
    return null;
  }
}))).map((el) => parseInt(el));

export const PadlockIDs = compactObj(flatten(HardwareTypeOptionMenu.map((el) => {
  if (["AP3"].includes(el.name)) {
    return el.value;
  } else {
    return null;
  }
}))).map((el) => parseInt(el));

// given a list of hardware_ids, is there a controller or padlock present
export const ControllerPresentInList = (list) => (intersection(ControllerIDs, list).length > 0);
export const PadlockPresentIntList = (list) => (intersection(PadlockIDs, list).length > 0);

export const LockConfigOptions = {
  auth_mode: [
    { id: 0, name: "1 User" },
    { id: 1, name: "2 Users" },
    { id: 2, name: "3 Users" },
    { id: 3, name: "4 Users" }
  ],
  charge_mode: [
    { id: 0, name: "Disabled" },
    { id: 1, name: "Enabled" }
  ],
  debug_mode: [
    { id: 0, name: "Default" },
    { id: 1, name: "Mode 1" },
    { id: 2, name: "Mode 2" },
    { id: 3, name: "Mode 3" }
  ],
  door_sensor_type: [
    { id: 0, name: "N.O. (Normally Open)" },
    { id: 1, name: "N.C. (Normally Closed)" }
  ],
  fsu_mode: [
    { id: 0, name: "Disabled" },
    { id: 1, name: "24 hours" },
    { id: 2, name: "4 hours" }
  ],
  gpio_mode: [
    { id: 0, name: "Disabled" },
    { id: 1, name: "AccessPad" },
    { id: 2, name: "Exit Button" },
    { id: 3, name: "AccessPad+Open" },
    { id: 4, name: "PIR Sensor" }
  ],
  lock_sensor_type: [
    { id: 0, name: "N.O. (Normally Open)" },
    { id: 1, name: "N.C. (Normally Closed)" }
  ],
  maintenance_mode: [
    { id: 0, name: "Default" },
    { id: 1, name: "Mode 1" },
    { id: 2, name: "Mode 2" },
    { id: 3, name: "Mode 3" }
  ],
  power_mode: [
    { id: 0, name: "Normal" },
    { id: 1, name: "High" },
    { id: 2, name: "Low" }
  ],
  rtm_interface: [
    { id: 0, name: "Disabled" },
    { id: 1, name: "Enabled" }
  ],
  sleep_advertising: [
    { id: 0, name: "Disabled" },
    { id: 1, name: "Enabled" }
  ],
  unlatch_by_default: [
    { id: 0, name: "Disabled" },
    { id: 1, name: "Enabled" }
  ],
  ble_advertising_interval: [
    { id: 0, name: "Default" },
    { id: 1, name: "4000 ms" },
    { id: 2, name: "2000 ms" },
    { id: 3, name: "1000 ms" },
    { id: 4, name: "500 ms" },
    { id: 5, name: "200 ms" },
    { id: 6, name: "100 ms" },
    { id: 7, name: "0 ms - wake on button" }
  ],
  ble_tx_power: [
    { id: 0, name: "0 dBm" },
    { id: 1, name: "-4 dBm" },
    { id: 2, name: "-8 dBm" },
    { id: 3, name: "-16 dBm" },
    { id: 4, name: "4 dBm" },
    { id: 5, name: "8 dBm" },
    { id: 6, name: "-16 dBm" }, //Duplicated in embedded code, not a mistake here
    { id: 7, name: "Default" }
  ]
};

export const HardwareDefaultRequiredInfoOptions = {
  crypto_current : "root",
  crypto_next : "poc"
};

export const HardwareDefaultConfigOptions = {
  auth_mode : 0,
  charge_mode : 0,
  debug_mode : 0,
  door_sensor_type : 0,
  fsu_mode : 2,
  gpio_mode : 0,
  lock_sensor_type : 0,
  maintenance_mode : 0,
  power_mode : 0,
  rtm_interface : 0,
  sleep_advertising : 0,
  unlatch_by_default : 0,
  ble_advertising_interval: 0,
  ble_tx_power : 0,
  relatch_time: 0,
  lock_control_green: 0,
  lock_control_orange: 0
};

// For now, only SLC2 and AP3 has overwrites
export const HardwareSpecificConfigOptions = {
  //AP3
  1: { power_mode : 2 },
  5: { power_mode : 2 },
  //SLC2
  6 : {
    gpio_mode : 1 // AccessPad as defined above
  },
  14 : { gpio_mode : 1 },
  15 : { gpio_mode : 1 }
};

LockConfigOptions.open_delay = getRangeOptions(0, 60);
LockConfigOptions.relatch_time = getRangeOptions(0, 255);
LockConfigOptions.lock_control_green = getRangeOptions(0, 15);
LockConfigOptions.lock_control_orange = getRangeOptions(0, 15);

export const LockConfigOptionsLabels = {
  auth_mode: { label: "Multi-Authentication Unlock", name: "lock.configs.authMode"},
  ble_advertising_interval: { label: "Advertising Interval", name: "lock.configs.bleAdvertisingInterval"},
  ble_tx_power: { label: "TX Power", name: "lock.configs.bleTxPower"},
  charge_mode: { label: "Battery Charging", name: "lock.configs.chargeMode"},
  debug_mode: { label: "Debug Mode", name: "lock.configs.debugMode"},
  door_sensor_type: { label: "Door Sensor Type", name: "lock.configs.doorSensorType"},
  fsu_mode: { label: "FSU Mode", name: "lock.configs.fsuMode"},
  gpio_mode: { label: "Button Mode", name: "lock.configs.gpioMode"},
  lock_sensor_type: { label: "Lock Sensor Type", name: "lock.configs.lockSensorType"},
  maintenance_mode: { label: "Maintenance Mode", name: "lock.configs.maintenanceMode"},
  open_delay: { label: "Delayed Entry", name: "lock.configs.openDelay"},
  power_mode: { label: "Power Mode", name: "lock.configs.powerMode"},
  relatch_time: { label: "Relatch Time (Seconds)", name: "lock.configs.relatchTime"},
  lock_control_green: { label: "Lock Control Green", name: "lock.configs.lockControlGreen"},
  lock_control_orange: { label: "Lock Control Orange", name: "lock.configs.lockControlOrange"},
  rtm_interface: { label: "RTM Interface", name: "lock.configs.rtmInterface"},
  sleep_advertising: { label: "Sleep Advertising", name: "lock.configs.sleepAdvertising"},
  unlatch_by_default: { label: "Connected Latch", name: "lock.configs.unlatchByDefault"}
};

export const LockBatteryStatus = Object.freeze({
  0: "low",
  1: "good"
});

export const LockOpenStatus = Object.freeze({
  true: "locked",
  false: "unlocked"
});

export const LockRtmStatus = Object.freeze({
  true: "connected",
  false: "disconnected"
});

export const noBatteryType = Object.freeze({
  id: -1,
  name: "No Battery"
});

export const defaultShackle = Object.freeze({
  name: "Unknown",
  value: "unknown"
});

export const shackleTypes = [
  {name: "Regular", value: "regular"},
  {name: "Long", value: "long"},
  {name: "Thin", value: "thin"}
];

export const controllerTypes = ["MX5", "AX5", "MX4"];