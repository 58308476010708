import { useCallback, useEffect, useReducer } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { isEmpty, isEqual } from "lodash";
import { useForm } from "react-hook-form";
import { useCustomCompareMemo, useCustomCompareCallback } from "use-custom-compare";

import { MAX_EMAIL_LENGTH, MAX_USERNAME_LENGTH } from "_constants";
import { userNameCannotContainsRegex } from "_helpers";
import { usernameRegex } from "_helpers/validator";
import { genericReducer } from "_reducers/general.reducer";

import {
  Button,
  TextField,
  Grid,
  Step,
  Stepper,
  StepLabel,
  Typography
} from "@mui/material";

import Loader from "_components/Loading";
import SelectLanguage from "_components/Select/SelectLanguage";
import Password from "_components/TextField/Password";

import { makeStyles } from "tss-react/mui";
import styles from "assets/jss/components/Form/personalInformationStyle";

const useStyles = makeStyles()(styles);

const PersonalInformation = (props) => {
  const { classes } = useStyles();
  const { t } = useTranslation(["auth", "default"]);
  const { onChangeLanguage, email, username, onChangeUsername, firstName, onChangeFirstName, lastName,
    onChangeLastName, onConfirmPersonalInfo, onCancel, defaultLanguageValue } = props;
  const [ state, setState ] = useReducer(genericReducer,
    {
      email: email || "",
      username: username || "",
      usernameError: false,
      firstName: firstName || "",
      firstNameError: false,
      lastName: lastName || "",
      lastNameError: false,
      activeStep: 0
    }
  );

  const passwordForm = useForm({ mode: "onChange" });

  const steps = () => Object.freeze([
    t("default:label.personalInfo"),
    t("default:label.createPassword")
  ]);

  const isLastStep = state.activeStep === steps().length - 1;

  useEffect(() => {
    setState({
      email: email || "",
      username: username || "",
      firstName: firstName || "",
      lastName: lastName || ""
    });
  }, [email, username, firstName, lastName]);

  const handleUsername = (event) => {
    const username = event.target.value;

    setState({
      username: username,
      usernameError: isEmpty(username) ? false : !usernameRegex.test(username)
    });
    onChangeUsername(username);
  };

  const handleFirstName = (event) => {
    const firstName = event.target.value;
    
    setState({
      firstName: firstName,
      firstNameError: !userNameCannotContainsRegex.test(firstName)
    });
    onChangeFirstName(firstName);
  };

  const handleLastName = (event) => {
    const lastName = event.target.value;

    setState({
      lastName: lastName,
      lastNameError: !userNameCannotContainsRegex.test(lastName)
    });
    onChangeLastName(lastName);
  };

  const firstStep = () => {
    return <Grid container direction="column" justifyContent="center" alignItems="stretch" spacing={2}>
      <Grid item>
        <Grid container justifyContent="center" alignItems="center" spacing={2}>
          <Grid item xs={10}>
            <TextField
              variant="outlined"
              fullWidth required
              error={state.emailError}
              id="login.name"
              label={t("login.emailAddress")}
              type="email"
              inputProps={{maxLength: MAX_EMAIL_LENGTH}}
              name="email"
              disabled
              value={state.email}
            />
          </Grid>
          <Grid item xs={10}>
            <TextField
              variant="outlined"
              fullWidth
              error={state.usernameError}
              label={t("login.username")}
              inputProps={{maxLength: MAX_USERNAME_LENGTH}}
              name="username"
              autoFocus={true}
              value={state.username}
              helperText={state.usernameError ? t("default:error.invalidUsername") : t("default:instructions.optionalUsername")}
              onChange={handleUsername}
            />
          </Grid>
          <Grid item xs={10}>
            <TextField
              variant="outlined"
              fullWidth
              required
              error={state.firstNameError}
              id="login.first_name"
              label={t("login.firstName")}
              name="first_name"
              value={state.firstName}
              helperText={state.firstNameError ? t("default:error.invalidName") : null}
              onChange={handleFirstName}
            />
          </Grid>
          <Grid item xs={10}>
            <TextField
              variant="outlined"
              fullWidth
              required
              error={state.lastNameError}
              id="login.last_name"
              label={t("login.lastName")}
              name="last_name"
              value={state.lastName}
              helperText={state.lastNameError ? t("default:error.invalidName") : null}
              onChange={handleLastName}
            />
          </Grid>
          <Grid item xs={10}>
            <SelectLanguage language={defaultLanguageValue} handleSubmit={onChangeLanguage}/>
          </Grid>
        </Grid>
      </Grid>
    </Grid>;
  };

  const handleSaveInfo = useCallback(data => {
    const { password } = data;
    onConfirmPersonalInfo(password);
  }, [onConfirmPersonalInfo]);

  const createPassword = useCustomCompareMemo(() => {
    const validatePWConfirmation = {
      validateEntry: passwordConfirmation => isEqual(passwordForm.getValues("password"), passwordConfirmation),
      validateEntryMessage: t("default:error.passwordMatch")
    };

    return <form name="signupForm">
      <Grid container justifyContent="center" alignItems="center" spacing={2}>
        <Grid item xs={10}>
          <Grid container direction="column" justifyContent="center" alignItems="stretch" spacing={2}>
            <Grid item>
              <Typography variant="body2">
                {t("password.instruction")}
              </Typography>
            </Grid>
            <Grid item>
              <Password
                form={passwordForm}
                label={t("login.password")}
                fieldName="password"
                showHelperText={true}
                shouldValidatePwStructure={true}
                fullWidth
                size="medium"
                columns={12}
              />
            </Grid>
            <Grid item>
              <Password
                form={passwordForm}
                label={t("login.confirmPassword")}
                fieldName="password_confirm"
                shouldValidatePwStructure={true}
                validate={validatePWConfirmation}
                submitOnEnter={true}
                showHelperText={true}
                handleSubmit={handleSaveInfo}
                fullWidth
                size="medium"
                columns={12}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>;
  }, [passwordForm, handleSaveInfo, t], (prevDeps, nextDeps) => isEqual(prevDeps, nextDeps));

  const renderStepContent = () => {
    switch (state.activeStep) {
      case 0:
        return firstStep();
      case 1:
        return createPassword;
      default:
        break;
    }
  };

  const handleNext = () => {
    if (isLastStep) {
      handleSaveInfo(passwordForm.getValues());
    } else {
      setState({ activeStep: state.activeStep + 1 });
    }
  };

  const handleBack = () => {
    setState({ activeStep: state.activeStep - 1 });
  };

  const pwObserver = passwordForm.watch("password");

  useEffect(() => {
    passwordForm.trigger("password_confirm");
  }, [pwObserver, passwordForm]);

  const isNextDisabled = useCustomCompareCallback(() => {
    const { password, password_confirm } = passwordForm.getValues();

    if (isLastStep) {
      return !isEmpty(Object.keys(passwordForm.formState.errors)) || isEmpty(password) || isEmpty(password_confirm);
    }

    return (state.usernameError || state.firstNameError || state.lastNameError);
  }, [isLastStep, passwordForm.formState.errors, state.firstNameError, state.lastNameError, state.usernameError],
  (prevDeps, nextDeps) => isEqual(prevDeps, nextDeps));
  
  const renderButtons = useCustomCompareCallback(() => {
    return <Grid container justifyContent="center" alignItems="center">
      <Grid item xs={10}>
        {state.submitted ? <Loader /> :
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Button
                variant="text"
                className={classes.button}
                onClick={onCancel}
              >
                {t("button.skip", { ns: "default" })}
              </Button>
            </Grid>
            <Grid item>
              <Grid container justifyContent="flex-end" alignItems="center">
                <Grid item>
                  <Button
                    variant="text"
                    className={classes.button}
                    disabled={state.activeStep === 0}
                    onClick={handleBack}
                  >
                    {t("button.back", { ns: "default" })}
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="text"
                    className={classes.button}
                    disabled={isNextDisabled()}
                    onClick={handleNext}
                  >
                    {isLastStep ? t("default:button.save") : t("button.next", { ns: "default" })}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        }
      </Grid>
    </Grid>;
  }, [isLastStep, onCancel, state.activeStep, state.submitted, t, isNextDisabled], (prevDeps, nextDeps) => isEqual(prevDeps, nextDeps));

  return <Grid container direction="column" justifyContent="center" alignItems="stretch" spacing={2} className={classes.margins}>
    <Grid item>
      <Stepper activeStep={state.activeStep} alternativeLabel>
        {steps().map((label) => {
          return (
            <Step key={label}>
              <StepLabel
                StepIconProps={{
                  classes: {
                    root: classes.icon,
                    active: classes.activeIcon,
                    completed: classes.completedIcon
                  }
                }}
              >
                {label}
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
    </Grid>
    <Grid item>
      {renderStepContent()}
    </Grid>
    <Grid item>
      {renderButtons()}
    </Grid>
  </Grid>;
};

PersonalInformation.propTypes = {
  defaultLanguageValue: PropTypes.string,
  onChangeLanguage: PropTypes.func.isRequired,
  email: PropTypes.string,
  username: PropTypes.string,
  onChangeUsername: PropTypes.func.isRequired,
  firstName: PropTypes.string,
  onChangeFirstName: PropTypes.func.isRequired,
  lastName: PropTypes.string,
  onChangeLastName: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirmPersonalInfo: PropTypes.func.isRequired
};

export default PersonalInformation;