import { useCallback, useEffect, useReducer } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";

import { alertActions } from "_actions";
import {
  formatDateAsMMMMDDYYYYhhmmLocal,
  formatLocation,
  generateGoolgeMapsUrlPoint,
  keyHelper,
  workSessionHelper
} from "_helpers";
import { genericReducer } from "_reducers/general.reducer";
import { workSessionService } from "_services/lockstasy";

import {
  Grid,
  Skeleton,
  Typography
} from "@mui/material";

import ErrorBoundary from "_components/ErrorBoundary";
import Placeholder from "_components/Helper/Placeholder";

import {
  DescriptionOutlined,
  Launch as LaunchIcon
} from "@mui/icons-material";

import { makeStyles } from "tss-react/mui";
import styles from "assets/jss/containers/widgets/workSessionDetailsStyle";

const useStyles = makeStyles()(styles);

function WorkSessionDetails(props) {
  const { hideTitle, selectedWorkSession } = props;

  const { classes, cx } = useStyles();
  const { t } = useTranslation("default");
  const dispatch = useDispatch();
  const currentMembership = useSelector((state) => state.memberships.currentMembership);

  const [state, setState] = useReducer(genericReducer,
    {
      workSession: {},
      isLoading: true
    }
  );

  const fetchWorkSession = useCallback(async () => {
    if (!selectedWorkSession?.id) {
      setState({ workSession: {}, isLoading: false });
      return;
    }

    setState({ isLoading: true });
    
    try {
      const result = await workSessionService.fetchWorkSession(selectedWorkSession.id);
      setState({ workSession: result.data, isLoading: false });
    } catch (e) {
      setState({ workSession: {}, isLoading: false });
      dispatch(alertActions.send(t("error.fetchWorkSessions"), "error"));
      console.warn("Warning, failed to fetch work sessions", e);
    }
  }, [selectedWorkSession?.id, dispatch, t]);

  useEffect(() => {
    fetchWorkSession();
  }, [fetchWorkSession]);

  const renderLoading = () => {
    return <Grid container spacing={2}>
      <Grid item xs={6}>
        <Skeleton variant="text" height={40} data-testid="skeleton"/>
      </Grid>
      <Grid item xs={6}>
        <Skeleton variant="text" height={40} data-testid="skeleton"/>
      </Grid>
      <Grid item xs={6}>
        <Skeleton variant="text" height={40} data-testid="skeleton"/>
      </Grid>
      <Grid item xs={6}>
        <Skeleton variant="text" height={40} data-testid="skeleton"/>
      </Grid>
      <Grid item xs={6}>
        <Skeleton variant="text" height={40} data-testid="skeleton"/>
      </Grid>
      <Grid item xs={6}>
        <Skeleton variant="text" height={40} data-testid="skeleton"/>
      </Grid>
      <Grid item xs={12}>
        <Skeleton variant="text" height={40} data-testid="skeleton"/>
      </Grid>
      <Grid item xs={12}>
        <Skeleton variant="text" height={40} data-testid="skeleton"/>
      </Grid>
    </Grid>;
  };

  const renderPlaceholder = () => {
    return <Placeholder
      message={t("fallbacks.noWorkSessionSelected")} 
      icon={<DescriptionOutlined/>}
      classNameMessage={classes.placeholderText}
      classNameIcon={classes.placeholderIcon}
    />;
  };

  const getFormattedLocation = location => {
    return location ? 
      <span className={classes.location}>
        <a
          href={generateGoolgeMapsUrlPoint(location)}
          className={classes.redirectLink}
          target="_blank"
          rel="noreferrer"
        >
          {formatLocation(location)}
          <LaunchIcon className={classes.redirectIcon}/>
        </a>
      </span> : null;
  };

  const renderWorkSessionInfo = () => {
    if (state.isLoading) {
      return renderLoading();
    }
    
    return isEmpty(state.workSession) ? renderPlaceholder() :
      <Grid container spacing={3}>
        <Grid item xs={6} data-testid="WorkSessionDetailsStartedAt">
          <Typography variant="body1" className={classes.value}>
            <b>{t("label.startedAt")}</b>{` ${formatDateAsMMMMDDYYYYhhmmLocal(state.workSession.started_at_time)}`}
          </Typography>
          <Typography variant="body1" className={cx(classes.value, classes.inlineFlex)}>
            <b>{t("label.location")}</b>{getFormattedLocation(state.workSession.started_at_location)}
          </Typography>
        </Grid>
        <Grid item xs={6} data-testid="WorkSessionDetailsEndedAt">
          <Typography variant="body1" className={classes.value}>
            <b>{t("label.endedAt")}</b>{` ${formatDateAsMMMMDDYYYYhhmmLocal(state.workSession.ended_at_time)}`}
          </Typography>
          <Typography variant="body1" className={cx(classes.value, classes.inlineFlex)}>
            <b>{t("label.location")}</b>{getFormattedLocation(state.workSession.ended_at_location)}
          </Typography>
        </Grid>
        <Grid item xs={6} data-testid="WorkSessionDetailsTimeAllowed">
          <Typography variant="body1" className={classes.value}>
            <b>{t("workSession.timeAllowed")}</b>{` ${state.workSession.wstl_as_time}`}
          </Typography>
        </Grid>
        <Grid item xs={6} data-testid="WorkSessionDetailsDuration">
          <Typography variant="body1" className={classes.value}>
            <b>{t("label.duration")}</b>
            <span className={state.workSession.time_exceeded ? classes.redColor : null} data-testid="durationValue">
              {` ${state.workSession.duration || t("label.ongoing")}`}
            </span>
          </Typography>
        </Grid>
        <Grid item xs={6} data-testid="WorkSessionDetailsStatus">
          <Typography variant="body1" className={classes.value}>
            <b>{t("workSession.status.status")}</b>{` ${workSessionHelper.getStatusName(currentMembership, t, state.workSession.status)}`}
          </Typography>
        </Grid>
        <Grid item xs={6} data-testid="WorkSessionDetailsCloseConfirmed">
          <Typography variant="body1" className={classes.value}>
            <b>{t("workSession.closeConfirmed")}</b>
            <span className={!state.workSession.close_confirmed ? classes.redColor : null} data-testid="closeConfirmedValue">
              {` ${state.workSession.close_confirmed ? t("label.yes") : t("label.no")}`}
            </span>
          </Typography>
        </Grid>
        <Grid item xs={6} data-testid="WorkSessionDetailsDevice">
          <Typography variant="body1" className={classes.value}>
            <b>{t("label.device")}</b>{` ${state.workSession.mobile_device}`}
          </Typography>
        </Grid>
        {keyHelper.hasTasks(currentMembership) &&
          <Grid item xs={6} data-testid="WorkSessionDetailsTicketNumber">
            <Typography variant="body1" className={classes.value}>
              <b>{t("label.reference")}</b>{` ${state.workSession.options?.ticket_number || ""}`}
            </Typography>
          </Grid>
        }
        {keyHelper.hasTasks(currentMembership) &&
          <Grid item xs={6} data-testid="WorkSessionDetailsSite">
            <Typography variant="body1" className={classes.value}>
              <b>{t("widgetField.site")}</b>{` ${state.workSession.site?.name || ""}`}
            </Typography>
          </Grid>
        }
        <Grid item xs={12} data-testid="WorkSessionDetailsComments">
          <Typography variant="body1" className={classes.value}>
            <b>{t("label.comments")}</b>{` ${state.workSession.comments || ""}`}
          </Typography>
        </Grid>
      </Grid>;
  };

  return <ErrorBoundary>
    <Grid container direction="column" spacing={2}>
      {!hideTitle && <Grid item data-testid="WorkSessionDetailsTitle">
        <Typography variant="body1"><b>{t("label.details")}</b></Typography>
      </Grid>}
      <Grid item data-testid="WorkSessionDetailsContentSection">
        {renderWorkSessionInfo()}
      </Grid>
    </Grid>
  </ErrorBoundary>;
}

WorkSessionDetails.defaultProps = {
  hideTitle: false
};

WorkSessionDetails.propTypes = {
  selectedWorkSession: PropTypes.object,
  hideTitle: PropTypes.bool
};

export default WorkSessionDetails;