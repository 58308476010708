import { useState } from "react";
import PropTypes from "prop-types";
import { isEmpty } from "lodash";

import {
  Chip,
  IconButton,
  InputAdornment,
  TextField
} from "@mui/material";

import {
  Search as SearchIcon
} from "@mui/icons-material";

import { makeStyles } from "tss-react/mui";
import styles from "assets/jss/components/textField/chipTextFieldStyle";

const useStyles = makeStyles()(styles);

const ChipTextField = (props) => {
  const { name, value, onSubmit } = props;

  const { classes } = useStyles();

  const [text, setText] = useState(value || "");

  const resetText = () => {
    if (isEmpty(text)) {
      return;
    }

    setText("");
    onSubmit("");
  };

  const handleOnSubmit = (event) => {
    if (event.key === "Enter") {
      onSubmit(text);
      event.target.blur();
    }
  };

  const handleOnChange = event => setText(event.target.value);

  const applyButton = <InputAdornment position="end">
    <IconButton
      tabIndex={-1}
      onClick={onSubmit}
      size="small"
      className={classes.iconButton}
    >
      <SearchIcon fontSize="small" className={classes.icon}/>
    </IconButton>
  </InputAdornment>;

  return <Chip
    data-testid={`textFieldChip-${name}`}
    className={classes.chip}
    onDelete={resetText}
    label={
      <TextField
        data-testid={`textField-${name}`}
        placeholder={name}
        size="small"
        InputProps={{ endAdornment: applyButton, className: classes.textFieldInput }}
        className={classes.textField}
        value={text}
        onKeyDown={handleOnSubmit}
        onChange={handleOnChange}
      />
    }
  />;
};

ChipTextField.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  onSubmit: PropTypes.func.isRequired
};

export default ChipTextField;