import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { isEmpty } from "lodash";

import {
  Tooltip,
  Typography
} from "@mui/material";

import ErrorBoundary from "_components/ErrorBoundary";

import {
  SecurityUpdateGood as SecurityUpdateGoodIcon,
  SecurityUpdateWarning as SecurityUpdateWarningIcon
} from "@mui/icons-material";

import { makeStyles } from "tss-react/mui";
import styles from "assets/jss/components/device/appUpdateIconStyle";

const useStyles = makeStyles()(styles);

const AppUpdateIcon = (props) => {
  const { platform, version, showMessage } = props;

  const { classes } = useStyles();
  const { t } = useTranslation("default");
  const mobile = useSelector(state => state.mobile);

  const isNecessaryUpdate = () => {
    if (isEmpty(version) || isEmpty(platform) || isEmpty(mobile?.min_version)) {
      return false;
    }
    
    return version < mobile.min_version[platform.toLowerCase()];
  };

  return isEmpty(version) || isEmpty(platform) ? null :
    <div className={classes.container}>
      <ErrorBoundary>
        {showMessage && <Typography className={classes.message}>{t("label.acceptedAppVersion")}</Typography>}
        <Tooltip title={isNecessaryUpdate() ? t("label.updateAppRequired") : t("label.acceptedAppVersion")}>
          {isNecessaryUpdate() ?
            <SecurityUpdateWarningIcon className={classes.icon} color="warning"/> :
            <SecurityUpdateGoodIcon className={classes.icon} color="disabled"/>
          }
        </Tooltip>
      </ErrorBoundary>
    </div>;
};

AppUpdateIcon.propTypes = {
  platform: PropTypes.string.isRequired,
  version: PropTypes.string.isRequired,
  showMessage: PropTypes.bool
};

export default AppUpdateIcon;