const userDevicesStyle = (theme) => ({
  body: {
    margin: `${theme.spacing(3)} ${theme.spacing(1.5)}`,
    display: "flex",
    justifyContent: "center",
    backgroundColor: theme.background,
    paddingBottom: theme.spacing(1.5)
  },
  title: {
    fontSize: theme.typography.largeFont.fontSize,
    marginTop: theme.spacing(1),
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },
  titleDescription: {
    color: theme.lsyPalette.primary.mainLight
  },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  deviceTitleFull: {
    whiteSpace: "nowrap",
    maxWidth: "225px",
    overflow: "hidden",
    textOverflow: "ellipsis"
  },
  deviceTitleShort: {
    display: "block",
    whiteSpace: "nowrap",
    maxWidth: "125px",
    overflow: "hidden",
    textOverflow: "ellipsis"
  },
  backContainer: {
    "display": "flex",
    "alignItems": "center",
    "marginTop": theme.spacing(0.5),
    "marginBottom": theme.spacing(1),
    "color": theme.lsyPalette.primary.mainLight,
    "fontSize": theme.typography.smallFont.fontSize,
    "width": "fit-content",
    "&:hover": {
      cursor: "pointer"
    }
  },
  icon: {
    "fontSize": theme.typography.largeFont.fontSize,
    "&:hover": {
      color: theme.lsyPalette.primary.mainDark
    }
  },
  container: {
    margin: `${theme.spacing(1)} 0 0 0`
  },
  deviceContainer: {
    display: "flex",
    flexWrap: "wrap",
    margin: `${theme.spacing(1)} 0`,
    gap: theme.spacing(1)
  },
  item: {
    "fontSize": theme.lsyPalette.body1,
    "alignItems": "center",
    "margin": `${theme.spacing(0.5)} 0`,
    "padding": `${theme.spacing(1)} ${theme.spacing(1.75)}`,
    "width": "fit-content",
    "maxWidth": "375px",
    "minWidth": "375px",
    "height": "fit-content",
    "border": "solid",
    "borderWidth": "0.5px",
    "borderRadius": theme.shape.borderRadius,
    "borderColor": theme.lsyPalette.border.lightGrey,
    "cursor": "pointer",
    "&:hover": {
      boxShadow: "0 4px 6px -6px #222",
      backgroundColor: theme.lsyPalette.primary.light
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    }
  },
  sentenceContainer: {
    display: "flex",
    flexDirection: "column",
    margin: `${theme.spacing(1)} 0`
  },
  openIcon: {
    "color": theme.lsyPalette.subText,
    "marginLeft": theme.spacing(1),
    "cursor": "pointer",
    "&:hover": {
      color: theme.lsyPalette.primary.mainDark
    }
  },
  sentence: {
    display: "flex",
    marginRight: theme.spacing(3),
    [theme.breakpoints.down("sm")]: {
      fontSize: theme.typography.xsFontSize,
      marginRight: theme.spacing(1)
    }
  },
  label: {
    color: theme.lsyPalette.subText,
    marginRight: theme.spacing(1)
  },
  value: {
    color: theme.lsyPalette.text,
    display: "block",
    whiteSpace: "nowrap",
    maxWidth: "250px",
    overflow: "hidden",
    textOverflow: "ellipsis"
  },
  red: {
    fill: theme.lsyPalette.stdRed
  },
  restricted: {
    color: theme.lsyPalette.stdRed,
    minHeight: theme.spacing(2.5)
  },
  flex: {
    display: "flex",
    alignItems: "center"
  },
  chipContainer: {
    marginTop: theme.spacing(2)
  },
  chip: {
    marginRight: theme.spacing(1)
  },
  chipHeader: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    flexWrap: "wrap"
  },
  chipText: {
    "color": theme.lsyPalette.text,
    "fontSize": theme.lsyPalette.subtitle3,
    "display": "inline",
    "fontWeight": theme.fontweightHeavy,
    "marginLeft": theme.spacing(0.5),
    "& span": {
      fontWeight: theme.typography.fontWeight
    }
  },
  pagination: {
    "display": "flex",
    "alignItems": "center",
    "marginTop": theme.spacing(5),
    "& div": {
      minHeight: "auto"
    },
    "& p": {
      fontSize: theme.lsyPalette.subtitle3
    },
    "& button": {
      "fontSize": theme.lsyPalette.subtitle3,
      "padding": theme.spacing(0.25),
      "& svg": {
        width: "16px",
        height: "16px"
      }
    },
    "& .MuiSelect-select": {
      fontSize: theme.lsyPalette.subtitle3,
      display: "flex",
      alignItems: "baseline",
      margin: 0,
      padding: "0"
    },
    "& svg": {
      width: "0.75em",
      height: "0.75em",
      top: "0px"
    },
    [theme.breakpoints.down("lg")]: {
      "& .MuiTablePagination-selectLabel": {
        display: "none"
      }
    },
    "& .MuiToolbar-root": {
      padding: 0,
      marginLeft: "auto"
    }
  },
  dateContainer: {
    margin: `${theme.spacing(1.5)} 0`
  },
  historyContainer: {
    display: "flex",
    margin:  `${theme.spacing(0.05)} ${theme.spacing(1)}`
  },
  marginBottom: {
    marginBottom: theme.spacing(1)
  },
  deviceSOIcon: {
    marginLeft: theme.spacing(1),
    fontSize: theme.typography.largeFont.fontSize
  },
  button: {
    textTransform: "none",
    fontSize: theme.lsyPalette.body1
  },
  center: {
    "display": "flex",
    "alignItems": "center",
    "& .MuiTypography-root": {
      margin: "0px",
      marginLeft: theme.spacing(1)
    }
  },
  textAlign: {
    marginTop: theme.spacing(2),
    textAlign: "center"
  },
  clickable: {
    cursor: "pointer"
  },
  skeleton: {
    width: "140px"
  },
  version: {
    display: "inline-flex",
    justifyContent: "space-between",
    alignItems: "center"
  }
});

export default userDevicesStyle;
