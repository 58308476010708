const ksc1Style = theme => ({
  disabled: {
    stroke: theme.lsyPalette.primary.mainLight
  },
  sfic: {
    fill: theme.lsyPalette.primary.background,
    stroke: theme.lsyPalette.primary.main,
    strokeMiterlimit: 10,
    strokeWidth: "5px"
  },
  externalLine: {
    strokeWidth: "10px"
  }
});

export default ksc1Style;