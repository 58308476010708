const workSessionTasksStyle = theme => ({
  listItem: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1)
  },
  placeholderIcon: {
    fontSize: "38px"
  },
  placeholderText: {
    fontSize: theme.lsyPalette.body1
  },
  subtitle: {
    fontSize: theme.lsyPalette.body1,
    color: theme.lsyPalette.primary.mainLight
  }
});

export default workSessionTasksStyle;