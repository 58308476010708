const userDetailsWidgetStyle = theme => ({
  tooltip: {
    backgroundColor: theme.lsyPalette.primary.mainHover,
    color: theme.lsyPalette.textContrast
  },
  icon: {
    color: theme.lsyPalette.primary.main
  },
  passwordIcon: {
    color: theme.lsyPalette.primary.main,
    transform: "scale(1.4)"
  },
  modal: {
    [theme.breakpoints.down("md")]: {
      width: "100%"
    },
    width: "500px"
  },
  rolesIcon: {
    fill: theme.lsyPalette.secondary.main,
    height: "30px",
    width: "30px"
  },
  helpIcon: {
    fill: theme.lsyPalette.primary.mainLight,
    transform: "scale(0.7)"
  },
  topBar: {
    marginTop: "5px",
    display: "flex",
    alignItems: "center"
  },
  name: {
    flexGrow: 1,
    fontSize: theme.lsyPalette.body3,
    color: theme.lsyPalette.text,
    fontWeight: theme.lsyPalette.heavyWeight
  },
  table: {
    width: "100%",
    fontSize: theme.lsyPalette.body1,
    color: theme.lsyPalette.text,
    marginTop: "10px"
  },
  field: {
    color: theme.lsyPalette.primary.mainLight
  },
  divider: {
    borderColor: theme.lsyPalette.primary.mainLight
  },
  chip: {
    marginRight: "3px"
  },
  chips: {
    marginTop: "5px",
    marginBottom: "5px"
  },
  actionLink: {
    color: theme.lsyPalette.link,
    cursor: "pointer"
  },
  iconButton: {
    marginRight: "5px"
  },
  value: {
    width: "70%"
  },
  itemBox: {
    width: "100%",
    marginBottom: "15px",
    marginTop: "15px"
  },
  root: {
    width: "100%"
  },
  footerSection: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    marginTop: theme.spacing(6)
  },
  device: {
    display: "flex",
    alignItems: "center"
  }
});
export default userDetailsWidgetStyle;
