import * as actionTypes from "../_actions/actionTypes";

let mobile;

try {
  mobile = JSON.parse(localStorage.getItem("mobile"));
} catch (e) {
  mobile = { min_version: {} };
}

const mobileSet = (state, action) => {
  return { ...state, ...action.mobile };
};

const reducer = (state = mobile, action) => {
  if (action.type === actionTypes.MOBILE_SET) {
    return mobileSet(state, action);
  } else {
    return state;
  }
};

export default reducer;