import { useCallback, useContext, useEffect, useReducer } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useCustomCompareCallback } from "use-custom-compare";
import { isEmpty, isEqual } from "lodash";
import PropTypes from "prop-types";
import queryString from "query-string";

import { alertActions } from "_actions";
import { LOCK_STATUS } from "_constants/lock.constants";
import { LsyAdminDataContext } from "_contexts/LsyAdminData/LsyAdminData";
import {
  compactObj,
  formatDateAsYYYYMMDD,
  formatDateAsMMMMDDYYYYhhmmss,
  formatDateStartDayToAPI,
  formatDateEndDayToAPI,
  formatLocation,
  generateGoolgeMapsUrlPoint,
  getMomentFor,
  lsyRouter
} from "_helpers";
import { getLockStatuses, getFormattedLockName } from "_helpers/lock";
import { useLsyHistory } from "_hooks";
import { genericReducer } from "_reducers/general.reducer";
import { lockService, reportService, userService } from "_services/lockstasy";

import {
  Chip,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Select,
  Skeleton,
  TablePagination,
  Typography,
  useMediaQuery
} from "@mui/material";

import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineDot from "@mui/lab/TimelineDot";

import ChipDateTimeRange from "_components/Date/ChipDateTimeRange";
import ErrorBoundary from "_components/ErrorBoundary";
import Placeholder from "_components/Helper/Placeholder";
import CustomModal from "_components/Modal/CustomModal";
import SelectExport from "_components/Select/SelectExport";
import SelectLock from "_components/Select/SelectLock";
import SelectUser from "_components/Select/SelectUser";

import {
  Clear as ClearIcon,
  GroupRounded,
  Launch as LaunchIcon,
  List as ListIcon,
  Lock as LockIcon,
  LockOpen as LockOpenIcon,
  Timeline as TimelineIcon
} from "@mui/icons-material";

import LockIcons from "assets/teleporte/LockIcons";

import { makeStyles } from "tss-react/mui";
import styles from "assets/jss/containers/presenceDetectionWidgetStyle";

const useStyles = makeStyles()(styles);

const defaultInitialState = {
  start_date: null,
  end_date: null,
  lock_status: LOCK_STATUS.active,
  selectedUsersLocks: []
};

function PresenceDetectionWidget(props) {
  const { id, type } = props;
  const { classes } = useStyles();
  const { t } = useTranslation("default");
  const dispatch = useDispatch();
  const history = useLsyHistory();
  const { search } = history.location;
  const isMdScreen = useMediaQuery((theme) => theme.breakpoints.only("md"));
  const lsyAdminDataContext = useContext(LsyAdminDataContext);
  const ability = lsyAdminDataContext.ability;
  const canReadDeactivationLock = ability.can("read", "locks.deactivation");
  const isLockTable = useCallback(() => type === "lock", [type]);
  const lockStatusOptions = getLockStatuses(t);

  const getInitialState = () => {
    const filterVars = {
      ...defaultInitialState,
      ...search ? queryString.parse(search) : {}
    };

    const filterOptions = compactObj({ ...filterVars });
    const filterOptionsKeys = Object.keys(filterOptions);
    const formattedFilterOp = {
      ...filterOptions.start_date ? { start_date: getMomentFor(filterOptions.start_date) } : {},
      ...filterOptions.end_date ? { end_date: getMomentFor(filterOptions.end_date) } : {}
    };

    filterOptionsKeys.forEach(field => {
      if (field === "membership_ids" || field === "lock_ids") {
        if (Array.isArray(filterOptions[field])) {
          formattedFilterOp["selectedUsersLocks"] = filterOptions[field].map(item => {
            const [ name, id ] = item.split(",");
            return { name, id };
          });
        } else {
          const [ name, id ] = filterOptions[field].split(",");
          formattedFilterOp["selectedUsersLocks"] = [{ name, id }];
        }
      } else {
        formattedFilterOp[field] = filterOptions[field];
      }
    });

    return { ...formattedFilterOp };
  };

  const initialState = getInitialState();

  const [state, setState] = useReducer(genericReducer,
    {
      data: [],
      filterVariables: initialState,
      totalQuantity: 0,
      currentPage: 1,
      pageSize: 10,
      isLoading: true,
      sortDirection: "desc",
      openSearchModal: false,
      usersLocks: []
    }
  );

  const updateUrlQuery = (newState) => {
    const newFilterVariables = {
      ...newState,
      ... newState.start_date && { start_date: formatDateAsYYYYMMDD(newState.start_date) },
      ... newState.end_date && { end_date: formatDateAsYYYYMMDD(newState.end_date) }
    };

    const filterVariables = {
      ...state.filterVariables,
      ... state.filterVariables.start_date && { start_date: formatDateAsYYYYMMDD(state.filterVariables.start_date) },
      ... state.filterVariables.end_date && { end_date: formatDateAsYYYYMMDD(state.filterVariables.end_date) }
    };
    
    if (!isEqual(filterVariables, newFilterVariables)) {
      const filterOptions = compactObj({ ...newFilterVariables });
      const filterOptionsKeys = Object.keys(filterOptions);
      const formattedFilterOp = {};

      filterOptionsKeys.forEach(field => {
        if (Array.isArray(filterOptions[field])) {
          if (field === "selectedUsersLocks") {
            const newField = isLockTable() ? "membership_ids" : "lock_ids";
            formattedFilterOp[newField] = filterOptions[field].map(item => `${item.name},${item.id}`);
          }
        } else {
          formattedFilterOp[field] = filterOptions[field];
        }
      });

      const filterString = queryString.stringify({...compactObj({ ...formattedFilterOp })}, { arrayFormat: "separator", arrayFormatSeparator: "|" });
      const url = `${lsyRouter("lock_presence_detection", id)}?${filterString}`;
      history.replace(url, history.location.state);
    }
  };

  const setFilterVariables = newFilters => {
    const newFilterVariables = { ...state.filterVariables, ...newFilters };
    updateUrlQuery(newFilterVariables);
    setState({ filterVariables: newFilterVariables });
  };
  const setDateRange = dateRange => setFilterVariables({ start_date: dateRange[0], end_date: dateRange[1] });
  const setOpenSearchModal = open => setState({ openSearchModal: open });
  const openSearchModal = () => setState({ openSearchModal: true, usersLocks: [...state.filterVariables.selectedUsersLocks || []] });
  const addUserLock = useCallback(userLock => setState({ usersLocks: [...state.usersLocks, userLock] }), [state.usersLocks]);
  const removeUserLock = useCallback(userLock => {
    const filteredUsersLocks = state.usersLocks.filter(item => item.id !== userLock.id);
    setState({ usersLocks: filteredUsersLocks });
  }, [state.usersLocks]);
  const setLockStatus = lockStatus => setFilterVariables({ lock_status: lockStatus });
  const handleLockStatus = (event) => setLockStatus(event.target.value);

  const handlChangePage = (_, newPage) => {
    setState({ currentPage: newPage + 1 });
  };

  const handleChangeRowsPerPage = (event) => {
    setState({
      pageSize: parseInt(event.target.value),
      currentPage: 1
    });
  };

  const getFilterOptions = useCustomCompareCallback(() => {
    const { start_date, end_date, selectedUsersLocks, lock_status } = state.filterVariables;
    return {
      start_date: start_date ? formatDateStartDayToAPI(start_date) : "",
      end_date: end_date ? formatDateEndDayToAPI(end_date) : "",
      ...isLockTable() ?
        { membership_ids: selectedUsersLocks?.map(item => item.id), lock_status: LOCK_STATUS.all } :
        { lock_ids: selectedUsersLocks?.map(item => item.id), lock_status }
    };
  }, [state.filterVariables, isLockTable], (prevDeps, nextDeps) => isEqual(prevDeps, nextDeps));

  const fetchRts = useCallback(async () => {
    setState({ isLoading: true });
    try {
      const options = {
        ...compactObj(getFilterOptions()),
        page_size: state.pageSize,
        page: state.currentPage,
        sort: state.sortDirection
      };

      const result = isLockTable() ?
        await lockService.fetchFieldUpdates(id, options) :
        await userService.fetchFieldUpdates(id, options);

      setState({
        data: result.data,
        totalQuantity: result.meta.pagination.total,
        isLoading: false
      });
    } catch (e) {
      setState({ data: [], totalQuantity: 0, isLoading: false });
      console.warn("Warning, failed to fetch rts", e?.response);
      dispatch(alertActions.send(t("error.fetchRts"), "error"));
    }
    setState({ isLoading: false });
  }, [id, state.currentPage, state.pageSize, state.sortDirection, getFilterOptions, isLockTable, dispatch, t]);

  useEffect(() => {
    fetchRts();
  }, [fetchRts]);

  const handleExportRTS = async (event) => {
    try {
      const options = {
        ...getFilterOptions(),
        ...isLockTable() ? { lock_ids: [id] } : { membership_ids: [id] },
        export: event.target.value
      };

      await reportService.fetchLocksFieldUpdates(compactObj(options));
      dispatch(alertActions.send(t("success.exportReportsData")));
    } catch (e) {
      console.warn("Warning, failed to export", e);
      let errorMsg = t("label.error");
      const response = e.response || {};
      const errors = response.data?.error?.errors || [{}];

      if (response.status === 429) {
        errorMsg = t("reports.error.manyRequests");
      } else if (response.status === 422) {
        if (errors[0].detail === "error.rows.exceeded") {
          const maxRows = errors[0].boundaries?.max || "";
          errorMsg = t("reports.error.tooManyRows", { maxRows: maxRows });
        }
      } else {
        errorMsg = t("label.error");
      }
      dispatch(alertActions.send(errorMsg, "error"));
    }
  };

  const getSelectedUsersLocks = useCallback(() => {
    return <>
      <List dense={true} className={classes.itemsSelected}>
        {state.usersLocks.map(value => {
          const handleRemoveUserLock = () => removeUserLock(value);

          return <ListItem
            key={value.id}
            secondaryAction={
              <IconButton edge="end"
                aria-label="delete"
                size="small"
                onClick={handleRemoveUserLock}
              >
                <ClearIcon fontSize="small"/>
              </IconButton>
            }
          >
            <ListItemText primary={value.name}/>
          </ListItem>;
        })}
      </List>
      <Typography variant="caption" className={classes.selectedItemsText}>
        {t(isLockTable() ? "label.selectedUsers" : "instructions.selectedLocks", { count: state.usersLocks.length })}
      </Typography>
    </>;
  }, [state.usersLocks, classes, removeUserLock, isLockTable, t]);

  const getSelector = useCallback(() => {
    const optionDisabled = item => {
      return state.usersLocks.some(userLock => userLock.id === item.id);
    };

    return isLockTable() ?
      <SelectUser
        setUser={addUserLock}
        isOptionDisabled={optionDisabled}
      /> :
      <SelectLock
        lock={{}}
        lockFilter={{status: state.filterVariables.lock_status}}
        setLock={addUserLock}
        optionDisabled={optionDisabled}
        optionsLimit={10}
        className={classes.sizeBreak}
        hideDisabledOptions={true}
      />;
  }, [addUserLock, classes, state.filterVariables.lock_status, state.usersLocks, isLockTable]);

  const getSearchModalContent = useCallback(() => {
    return <Grid container className={classes.modalContent}>
      <Grid item xs={12}>
        {getSelector()}
      </Grid>
      <Grid item xs={12}>
        {getSelectedUsersLocks()}
      </Grid>
    </Grid>;
  }, [classes, getSelector, getSelectedUsersLocks]);
  
  const renderDateRange = () => {
    return <ChipDateTimeRange
      startDate={getMomentFor(state.filterVariables.start_date)}
      endDate={getMomentFor(state.filterVariables.end_date)}
      setDateRange={setDateRange}
    />;
  };

  const renderUserFilter = () => {
    const selectedUsers = state.filterVariables.selectedUsersLocks;

    return <Chip
      data-testid="chipUsers"
      label={
        <div className={classes.header}>
          <GroupRounded className={classes.chipIcon}/>
          <Typography className={classes.chipText}>
            {`${t("features.users")}${isEmpty(selectedUsers) ? "" : ": " + selectedUsers.length}`}
          </Typography>
        </div>}
      onClick={openSearchModal}
      className={classes.chip}
    />;
  };

  const renderLockFilter = () => {
    const selectedLocks = state.filterVariables.selectedUsersLocks;
    
    return <Chip
      data-testid="chipLocks"
      label={
        <div className={classes.header}>
          <LockIcons type="AP3" className={classes.chipIcon}/>
          <Typography className={classes.chipText}>
            {`${t("features.locks")}${isEmpty(selectedLocks) ? "" : selectedLocks.length}`}
          </Typography>
        </div>}
      onClick={openSearchModal}
      className={classes.chip}
    />;
  };

  const onFilterSubmit = () => {
    setFilterVariables({ selectedUsersLocks: state.usersLocks });
  };

  const resetUsersLocks = () => {
    setState({ usersLocks: [] });
    setFilterVariables({ selectedUsersLocks: [] });
  };

  const renderLockStatus = () => {
    const renderSelectedLockStatus = value => {
      return `${t("label.lockStatus")}: ${lockStatusOptions.find(op => op.value == value)?.name}`;
    };

    return <Select
      data-testid="lockStatusSelect"
      value={state.filterVariables.lock_status}
      className={classes.selectStatus}
      onChange={handleLockStatus}
      IconComponent={() => null}
      renderValue={renderSelectedLockStatus}
      size="small"
      SelectDisplayProps={{ style: { paddingRight: 12 }}}
    >
      {lockStatusOptions.map(option => (
        <MenuItem key={`lockStatus${option.value}`} value={option.value} className={classes.selectOptions}>
          {option.name}
        </MenuItem>
      ))}
    </Select>;
  };

  const renderFilters = () => {
    return <Grid container alignItems="center" justifyContent="space-between" spacing={1}>
      <Grid item>
        <Grid container alignItems="center" spacing={1}>
          <Grid item>
            { renderDateRange() }
          </Grid>
          {!isLockTable() && canReadDeactivationLock &&
            <Grid item>
              {renderLockStatus()}
            </Grid>
          }
          <Grid item>
            { isLockTable() ? renderUserFilter() : renderLockFilter() }
            <CustomModal
              open={state.openSearchModal}
              setOpen={setOpenSearchModal}
              type="custom"
              description={getSearchModalContent()}
              title={isLockTable() ? t("instructions.selectUsers") : t("instructions.selectLocks")}
              cancelButton
              confirmButton
              cancel={t("button.reset")}
              confirm={t("button.apply")}
              handleCancel={resetUsersLocks}
              handleSubmit={onFilterSubmit}
              modalStyle={classes.modal}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <SelectExport disabled={isEmpty(state.data)} submit={handleExportRTS}/>
      </Grid>
    </Grid>;
  };

  const renderPagination = () => {
    return !isEmpty(state.data) &&
      <TablePagination
        className={classes.pagination}
        data-testid="pagination"
        component="div"
        count={state.totalQuantity || 0}
        rowsPerPage={state.pageSize}
        page={state.currentPage - 1}
        onPageChange={handlChangePage}
        rowsPerPageOptions={[10, 15, 20, 25]}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />;
  };

  const renderLoading = () => {
    return <Skeleton className={classes.tableSkeleton} data-testid="skeleton" />;
  };

  const renderPlaceholder = () => {
    return <Placeholder
      message={t("fallbacks.noRtsFound")}
      classNameMessage={classes.placeholderText}
      icon={<ListIcon/>}
      classNameIcon={classes.placeholderIcon}
    />;
  };

  const getFormattedLocation = location => {
    return location ? 
      <span className={classes.location}>
        <a
          href={generateGoolgeMapsUrlPoint(location)}
          className={classes.redirectLink}
          target="_blank"
          rel="noreferrer"
        >
          {formatLocation(location)}
          <LaunchIcon className={classes.redirectIcon}/>
        </a>
      </span> : null;
  };

  const renderRTSInfo = rts => {
    return <Grid container justifyContent="flex-start">
      <Grid item xs={12}>
        <Typography variant="body2">
          <span className={classes.infoField}>{t("label.collectionTime")}</span>{`${formatDateAsMMMMDDYYYYhhmmss(rts.timestamp)}`}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body2" className={classes.inlineFlex}>
          <span className={classes.infoField}>{t("label.location")}</span>{getFormattedLocation(rts.location)}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body2">
          <span className={classes.infoField}>{t("label.locked")}</span>{`${rts.locked ? t("label.yes") : t("label.no")}`}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body2">
          <span className={classes.infoField}>{t("label.latched")}</span>{`${rts.latched ? t("label.yes") : t("label.no")}`}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body2">
          <span className={classes.infoField}>{t("label.rawData")}</span>{`${rts.raw_data || ""}`}
        </Typography>
      </Grid>
    </Grid>;
  };

  const renderRTSs = () => {
    if (state.isLoading) {
      return renderLoading();
    }

    if (isEmpty(state.data)) {
      return renderPlaceholder();
    }

    return <div data-testid="listRts">
      {state.data.map(rts => {
        const title = isLockTable() ? `${rts.user?.name} (${rts.user.email})` : getFormattedLockName(rts.lock);

        return <Grid container key={rts.timestamp} className={classes.rtsContainer} data-testid="listItemRts">
          <Grid item xs={12}>
            <Typography><b>{title}</b></Typography>
          </Grid>
          <Grid item xs={12}>
            {renderRTSInfo(rts)}
          </Grid>
        </Grid>;
      })}
    </div>;
  };

  const renderTimeline = () => {
    if (state.isLoading) {
      return renderLoading();
    }

    if (isEmpty(state.data)) {
      return <Placeholder
        message={t("fallbacks.noData")}
        classNameMessage={classes.placeholderText}
        icon={<TimelineIcon/>}
        classNameIcon={classes.placeholderIcon}
      />;
    }

    return <Timeline position={isMdScreen ? "left" : "alternate"} className={classes.marginTop}>
      {state.data.map(rts => {
        return <TimelineItem key={rts.timestamp}>
          <TimelineOppositeContent>
            <Grid container direction="column">
              <Grid item>
                <Typography className={classes.timelineHeader}>
                  <b>{rts.locked ? t("label.closed") : t("label.opened")}</b>
                </Typography>
              </Grid>
              <Grid item>
                <Typography className={classes.timelineContent}>
                  {formatDateAsMMMMDDYYYYhhmmss(rts.timestamp)}
                </Typography>
              </Grid>
              <Grid item>
                <Typography className={classes.timelineContent}>
                  {isLockTable() ? `${rts.user?.name} (${rts.user.email})` : rts.lock?.full_identifier}
                </Typography>
              </Grid>
            </Grid>
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineConnector />
            <TimelineDot {...!rts.locked && {color: "primary"}}>
              {rts.locked ? <LockIcon/> : <LockOpenIcon/>}
            </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent/>
        </TimelineItem>;
      })}
    </Timeline>;
  };

  return <ErrorBoundary>
    <Grid container justifyContent="space-between" alignItems={isEmpty(state.data) ? "flex-end" : "flex-start"}>
      <Grid item xs={12} md={5.5}>
        <Grid container justifyContent="flex-end" data-testid="listSection">
          <Grid item xs={12} data-testid="filterSection">
            { renderFilters() }
          </Grid>
          <Grid item data-testid="headerSection">
            { renderPagination() }
          </Grid>
          <Grid item xs={12} className={classes.width} data-testid="bodySection">
            { renderRTSs() }
          </Grid>
          <Grid item data-testid="footerSection">
            { renderPagination() }
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={6} data-testid="timelineSection">
        { renderTimeline() }
      </Grid>
    </Grid>
  </ErrorBoundary>;
}

PresenceDetectionWidget.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired
};

export default PresenceDetectionWidget;