import { isEmpty } from "lodash";
import moment from "moment";

import { formatDate, formatDateAsYYYYMMDD, formatParseDate, formatLocation, keyHelper } from "_helpers";
import { reportService } from "_services/lockstasy/report.service";

const userGroupMap = {
  "administrator": "form.administrators",
  "user": "features.users",
  "access.manager": "form.accessManagers",
  "configuration.manager": "form.configurationManagers"
};

const getHeaderOrdered = (tableData, selectedColumns) => {
  return isEmpty(tableData) ? selectedColumns : Object.keys(tableData[0]).map(key => {
    return selectedColumns.find(column => column.id === key);
  });
};

const formatTags = tags => {
  if (!tags) {
    return "";
  } else if (typeof tags === "string") {
    return tags.replaceAll(";", ", ");
  } else {
    return tags.map(tag => tag.name).join(", ");
  }
};

const filterColumns = (selectedColumns, row) => {
  if (selectedColumns.some(column => column.id === "all")) {
    return row;
  }

  let newRow = {};

  Object.keys(row).map(colId => {
    if (selectedColumns.some(column => column.id === colId)) {
      newRow = { ...newRow, [colId]: row[colId] };
    }
  });

  return newRow;
};

export const getReportTypeName = (reportType, t) => {
  const reportTypeMap = {
    lock_access_history: t("label.accessHistory"),
    keys: t("label.keys"),
    users: t("features.users"),
    locks: t("features.locks"),
    tags: t("features.tags"),
    system_events: t("features.systemLogs")
  };

  return reportTypeMap[reportType] || reportType;
};

export const urlMap = Object.freeze({
  accessHistory: "access_history",
  keys: "keys",
  users: "users",
  locks: "locks",
  tags: "tags",
  systemLogs: "system_logs",
  presenceDetection: "presence_detection"
});

const getReportLabels = (currentMembership, sera4Mode, t, classes) => {
  const hasTasks = keyHelper.hasTasks(currentMembership);

  const accessExports = {
    type: "Access",
    description: `${t("reports.accessDescription")}`,
    url: urlMap.accessHistory,
    fetchData: reportService.fetchLockAccess,
    filters: ["dates", "lockStatus", "users", "locks", "userTags", "lockTags"],
    initialFilterValues: {
      start_date: formatDateAsYYYYMMDD(moment().subtract(1, "month")),
      end_date: formatDateAsYYYYMMDD(moment()),
      selectedUsers: [],
      selectedLocks: [],
      selectedUserTags: [],
      selectedLockTags: [],
      orderBy: "",
      direction: "",
      lockStatus: 0
    },
    header: [
      { id: "lockId", label: t("form.lockId"), sortable: false, classNameProps: classes.tableHeaderCell },
      { id: "lockName", label: t("label.lockName"), sortable: false, classNameProps: classes.tableHeaderCell },
      { id: "lockGroup", label: t("label.lockGroup"), sortable: false, classNameProps: classes.tableHeaderCell },
      { id: "site", label: t("widgetField.site"), sortable: false, classNameProps: classes.tableHeaderCell },
      { id: "lockTags", label: t("label.lockTags"), sortable: false, classNameProps: classes.tableHeaderCell },
      { id: "user", label: t("widgetField.user"), sortable: false, classNameProps: classes.tableHeaderCell },
      { id: "email", label: t("form.email"), sortable: false, classNameProps: classes.tableHeaderCell },
      { id: "membershipType", label: t("label.membershipType"), sortable: false, classNameProps: classes.tableHeaderCell },
      { id: "userTags", label: t("label.userTags"), sortable: false, classNameProps: classes.tableHeaderCell },
      { id: "open", label: t("widgetField.open"), sortable: false, classNameProps: classes.tableHeaderCell },
      { id: "keyId", label: t("label.key.key"), sortable: false, classNameProps: classes.tableHeaderCell },
      { id: "reference", label: t("label.reference"), sortable: false, classNameProps: classes.tableHeaderCell },
      ...hasTasks ?
        [{ id: "tasks", label: t("label.tasks"), sortable: false, classNameProps: classes.tableHeaderCell }] : [],
      { id: "comments", label: t("label.comments"), sortable: false, classNameProps: classes.tableHeaderCell },
      { id: "openLocation", label: t("reports.openLocation"), sortable: false, classNameProps: classes.tableHeaderCell },
      { id: "closed", label: t("label.closed"), sortable: false, classNameProps: classes.tableHeaderCell },
      { id: "closedLocation", label: t("reports.closedLocation"), sortable: false, classNameProps: classes.tableHeaderCell },
      { id: "duration", label: t("label.duration"), sortable: false, classNameProps: classes.tableHeaderCell },
      { id: "information", label: t("reports.information"), sortable: false, classNameProps: classes.tableHeaderCell }
    ],
    formatData: (data, selectedColumns) => {
      const tableData = data.map(row => {
        return filterColumns(selectedColumns,
          {
            lockId: { text: row.lock?.hardware_id, classNameProps: classes.tableBodyCell },
            lockName: { text: row.lock?.name, classNameProps: classes.tableBodyCell },
            lockGroup: { text: row.lock_group?.name, classNameProps: classes.tableBodyCell },
            site: { text: row.site?.name, classNameProps: classes.tableBodyCell },
            lockTags: { text: formatTags(row.lock?.tags), classNameProps: classes.tableBodyCell },
            user: { text: row.user ? `${row.user.first_name} ${row.user.last_name}` : row.custom_label, classNameProps: classes.tableBodyCell },
            email: { text: row.user?.email, classNameProps: classes.tableBodyCell },
            membershipType: { text: row.user ? t(userGroupMap[row.user.user_group]) : "", classNameProps: classes.tableBodyCell },
            userTags: { text: formatTags(row.user?.tags), classNameProps: classes.tableBodyCell },
            open: { text: formatDate(row.open), classNameProps: classes.tableHeaderCell },
            keyId: { text: row.key?.id, classNameProps: classes.tableBodyCell },
            reference: { text: row.key?.ticket_number, classNameProps: classes.tableBodyCell },
            ...hasTasks &&
              {tasks: { text: row.key?.tasks?.join(", ") || "", classNameProps: classes.tableBodyCell }},
            comments: { text: row.key?.comments, classNameProps: classes.tableBodyCell },
            openLocation: { text: formatLocation(row.open_location), classNameProps: classes.tableBodyCell },
            closed: { text: formatDate(row.closed), classNameProps: classes.tableHeaderCell },
            closedLocation: { text: formatLocation(row.close_location), classNameProps: classes.tableBodyCell },
            duration: { text: row.time_spent, classNameProps: classes.tableBodyCell },
            information: { text: row.rts_info || row.key?.fsu, classNameProps: classes.tableBodyCell }
          }
        );
      });

      return [getHeaderOrdered(tableData, selectedColumns), tableData];
    }
  };

  const keysExports = {
    type: "Keys",
    description: `${t("reports.keyDescription")}`,
    url: urlMap.keys,
    fetchData: reportService.fetchKeys,
    filters: ["dates", "users", "locks", "lockGroups", "sites", "keyStatus"],
    sortMap: { validFrom: "start_date", validTill: "end_date" },
    initialFilterValues: {
      start_date: formatDateAsYYYYMMDD(moment().subtract(1, "month")),
      end_date: formatDateAsYYYYMMDD(moment()),
      selectedUsers: [],
      selectedLocks: [],
      selectedLockGroups: [],
      selectedSites: [],
      keyStatus: 0,
      orderBy: "validFrom",
      direction: "desc"
    },
    header: [
      { id: "hardwareId", label: t("label.hardwareId"), sortable: false, classNameProps: classes.tableHeaderCell },
      { id: "lockName", label: t("label.lockName"), sortable: false, classNameProps: classes.tableHeaderCell },
      { id: "site", label: t("widgetField.site"), sortable: false, classNameProps: classes.tableHeaderCell },
      { id: "lockGroup", label: t("label.lockGroup"), sortable: false, classNameProps: classes.tableHeaderCell },
      { id: "tags", label: t("features.tags"), sortable: false, classNameProps: classes.tableHeaderCell },
      { id: "email", label: t("form.email"), sortable: false, classNameProps: classes.tableHeaderCell },
      { id: "firstName", label: t("form.firstName"), sortable: false, classNameProps: classes.tableHeaderCell },
      { id: "lastName", label: t("form.lastName"), sortable: false, classNameProps: classes.tableHeaderCell },
      { id: "validFrom", label: t("label.validFrom"), sortable: true, classNameProps: classes.tableHeaderCell },
      { id: "validTill", label: t("label.validUntil"), sortable: true, classNameProps: classes.tableHeaderCell },
      { id: "issuedBy", label: t("label.issuedBy"), sortable: false, classNameProps: classes.tableHeaderCell },
      { id: "createdAt", label: t("label.createdAt"), sortable: false, classNameProps: classes.tableHeaderCell },
      { id: "reference", label: t("label.reference"), sortable: false, classNameProps: classes.tableHeaderCell },
      ...hasTasks ?
        [{ id: "tasks", label: t("label.tasks"), sortable: false, classNameProps: classes.tableHeaderCell }] : [],
      { id: "comments", label: t("label.comments"), sortable: false, classNameProps: classes.tableHeaderCell },
      { id: "information", label: t("reports.information"), sortable: false, classNameProps: classes.tableHeaderCell }
    ],
    formatData: (data, selectedColumns) => {
      const tableData = data.map(row => {
        const lock = row.lock || {};
        const user = row.user || {};

        return filterColumns(selectedColumns,
          {
            hardwareId: { text: lock.hardware_id, classNameProps: classes.tableBodyCell },
            lockName: { text: lock.name, classNameProps: classes.tableBodyCell },
            site: { text: lock.site?.name, classNameProps: classes.tableBodyCell },
            lockGroup: { text: row.lock_group?.name, classNameProps: classes.tableBodyCell },
            tags: { text: formatTags(row.tags), classNameProps: classes.tableBodyCell },
            email: { text: user.email, classNameProps: classes.tableBodyCell },
            firstName: { text: user.first_name, classNameProps: classes.tableBodyCell },
            lastName: { text: user.last_name, classNameProps: classes.tableBodyCell },
            validFrom: { text: formatDate(row.start_date), classNameProps: classes.tableHeaderCell },
            validTill: { text: formatDate(row.end_date), classNameProps: classes.tableHeaderCell },
            issuedBy: { text: `${row.issuer?.first_name} ${row.issuer?.last_name} (${row.issuer?.email})`, classNameProps: classes.tableBodyCell },
            createdAt: { text: formatDate(row.issued_at), classNameProps: classes.tableHeaderCell },
            reference: { text: row.ticket_number, classNameProps: classes.tableBodyCell },
            ...hasTasks &&
              {tasks: { text: row.tasks?.join(", ") || "", classNameProps: classes.tableBodyCell }},
            comments: { text: row.comments, classNameProps: classes.tableBodyCell },
            information: { text: row.fsu, classNameProps: classes.tableBodyCell }
          }
        );
      });

      return [getHeaderOrdered(tableData, selectedColumns), tableData];
    }
  };

  const userExports = {
    type: "Users",
    description: `${t("reports.userDescription")}`,
    url: urlMap.users,
    fetchData: reportService.fetchUsers,
    filters: ["users", "userStatus"], // Add in the future  "userRole", "userMfa"
    sortMap: { id: "id", updatedOn: "updated_at", firstName: "first_name", lastName: "last_name", createdOn: "created_at", deletedOn: "deleted_at" },
    initialFilterValues: {
      selectedUsers: [],
      userStatus: " ",
      orderBy: "updatedOn",
      direction: "desc"
    },
    header: [
      { id: "id", label: t("label.id"), sortable: true, classNameProps: classes.tableHeaderCell },
      { id: "email", label: t("form.email"), sortable: false, classNameProps: classes.tableHeaderCell },
      { id: "phoneNumber", label: t("label.phoneNumber"), sortable: false, classNameProps: classes.tableHeaderCell },
      { id: "firstName", label: t("form.firstName"), sortable: true, classNameProps: classes.tableHeaderCell },
      { id: "lastName", label: t("form.lastName"), sortable: true, classNameProps: classes.tableHeaderCell },
      { id: "userGroup", label: t("users.userGroup"), sortable: false, classNameProps: classes.tableHeaderCell },
      { id: "tags", label: t("features.tags"), sortable: false, classNameProps: classes.tableHeaderCell },
      { id: "status", label: t("label.status"), sortable: false, classNameProps: classes.tableHeaderCell },
      { id: "mfa", label: t("label.mfa"), sortable: false, classNameProps: classes.tableHeaderCell },
      { id: "password", label: t("form.password"), sortable: false, classNameProps: classes.tableHeaderCell },
      { id: "lastLogin", label: t("reports.lastLogin"), sortable: false, classNameProps: classes.tableHeaderCell },
      { id: "updatedOn", label: t("label.updatedAt"), sortable: true, classNameProps: classes.tableHeaderCell },
      { id: "orgId", label: t("label.organizationId"), sortable: false, classNameProps: classes.tableHeaderCell },
      { id: "username", label: t("auth:login.username"), sortable: false, classNameProps: classes.tableHeaderCell },
      { id: "deviceRestricted", label: t("label.deviceRestricted"), sortable: false, classNameProps: classes.tableHeaderCell },
      { id: "createdOn", label: t("label.created"), sortable: true, classNameProps: classes.tableHeaderCell },
      { id: "deletedOn", label: t("label.deleted"), sortable: true, classNameProps: classes.tableHeaderCell }
    ],
    formatData: (data, selectedColumns) => {
      const tableData = data.map(row => {
        return filterColumns(selectedColumns,
          {
            id: { text: row.id, classNameProps: classes.tableBodyCell },
            email: { text: row.email, classNameProps: classes.tableBodyCell },
            phoneNumber: { text: row.phone_number, classNameProps: classes.tableBodyCell },
            firstName: { text: row.first_name, classNameProps: classes.tableBodyCell },
            lastName: { text: row.last_name, classNameProps: classes.tableBodyCell },
            userGroup: { text: row.user_group_name, classNameProps: classes.tableBodyCell },
            tags: { text: formatTags(row.tags), classNameProps: classes.tableBodyCell },
            status: { text: row.active_status, classNameProps: classes.tableBodyCell },
            mfa: { text: row.mfa_enabled, classNameProps: classes.tableBodyCell },
            password: { text: row.password_enabled, classNameProps: classes.tableBodyCell },
            lastLogin: { text: formatDate(row.last_login), classNameProps: classes.tableHeaderCell },
            updatedOn: { text: formatDate(row.updated_at), classNameProps: classes.tableHeaderCell },
            orgId: { text: row.organization_id, classNameProps: classes.tableBodyCell },
            username: { text: row.username, classNameProps: classes.tableBodyCell },
            deviceRestricted: { text: row.device_restricted, classNameProps: classes.tableBodyCell },
            createdOn: { text: formatDate(row.created_at), classNameProps: classes.tableBodyCell },
            deletedOn: { text: formatDate(row.deleted_at), classNameProps: classes.tableBodyCell }
          }
        );
      });

      return [getHeaderOrdered(tableData, selectedColumns), tableData];
    }
  };

  const lockExports = {
    type: "Locks",
    description: `${t("reports.lockDescription")}`,
    url: urlMap.locks,
    fetchData: reportService.fetchLocks,
    filters: ["locks", "lockType", "lockStatus"],
    sortMap: { name: "name" },
    initialFilterValues: {
      selectedLocks: [],
      lockType: "",
      lockStatus: 0,
      orderBy: "name",
      direction: "asc"
    },
    header: [
      { id: "id", label: t("label.hardwareId"), sortable: false, classNameProps: classes.tableHeaderCell },
      { id: "name", label: t("form.name"), sortable: true, classNameProps: classes.tableHeaderCell },
      { id: "description", label: t("form.description"), sortable: false, classNameProps: classes.tableHeaderCell },
      { id: "site", label: t("widgetField.site"), sortable: false, classNameProps: classes.tableHeaderCell },
      { id: "lockGroup", label: t("label.lockGroup"), sortable: false, classNameProps: classes.tableHeaderCell },
      { id: "hardwareType", label: t("label.hardwareType"), sortable: false, classNameProps: classes.tableHeaderCell },
      { id: "configVersion", label: t("label.configurationVersion"), sortable: false, classNameProps: classes.tableHeaderCell },
      { id: "softwareVersion", label: t("label.softwareVersion"), sortable: false, classNameProps: classes.tableHeaderCell },
      { id: "tags", label: t("features.tags"), sortable: false, classNameProps: classes.tableHeaderCell },
      { id: "deactivated", label: t("label.deactivated"), sortable: false, classNameProps: classes.tableHeaderCell },
      { id: "license", label: t("widgetField.license"), sortable: false, classNameProps: classes.tableHeaderCell },
      { id: "validFrom", label: t("label.validFrom"), sortable: false, classNameProps: classes.tableHeaderCell },
      { id: "validTill", label: t("label.validUntil"), sortable: false, classNameProps: classes.tableHeaderCell },
      { id: "orgId", label: t("label.organizationId"), sortable: false, classNameProps: classes.tableHeaderCell },
      { id: "batteryLevel", label: t("label.batteryLevel"), sortable: false, classNameProps: classes.tableHeaderCell },
      { id: "location", label: t("label.lastLocation"), sortable: false, classNameProps: classes.tableHeaderCell }
    ],
    formatData: (data, selectedColumns) => {
      const tableData = data.map(row => {
        const classNameProps = row.deactivated === "Yes" ? classes.disabledTableBodyCell : classes.tableBodyCell;

        return filterColumns(selectedColumns,
          {
            id: { text: row.id, classNameProps },
            name: { text: row.name, classNameProps },
            description: { text: row.description, classNameProps },
            site: { text: row.site, classNameProps },
            lockGroup: { text: row.lock_group_name, classNameProps },
            hardwareType: { text: row.hardware_type, classNameProps },
            configVersion: { text: row.lock_configuration, classNameProps },
            softwareVersion: { text: row.lock_version, classNameProps },
            tags: { text: formatTags(row.lock_tags), classNameProps },
            deactivated: { text: row.deactivated, classNameProps },
            license: { text: row.license_name, classNameProps },
            validFrom: { text: row.license_from, classNameProps },
            validTill: { text: row.license_till, classNameProps },
            orgId: { text: row.org_id, classNameProps },
            batteryLevel: { text: row.battery_status, classNameProps },
            location: { text: typeof row.location === "string" ? row.location : formatLocation(row.location), classNameProps }
          }
        );
      });

      return [getHeaderOrdered(tableData, selectedColumns), tableData];
    }
  };

  const tagExports = {
    type: "Tags",
    description: `${t("reports.tagDescription")}`,
    url: urlMap.tags,
    fetchData: reportService.fetchTags,
    filters: ["tags"],
    sortMap: { tag: "name" },
    initialFilterValues: {
      selectedTags: [],
      orderBy: "tag",
      direction: "asc"
    },
    header: [
      { id: "id", label: t("label.id"), sortable: false, classNameProps: classes.tableHeaderCell },
      { id: "tag", label: t("label.tag"), sortable: true, classNameProps: classes.tableHeaderCell },
      { id: "resourceId", label: t("reports.resourceId"), sortable: false, classNameProps: classes.tableHeaderCell },
      { id: "name", label: t("form.name"), sortable: false, classNameProps: classes.tableHeaderCell },
      { id: "type", label: t("widgetField.type"), sortable: false, classNameProps: classes.tableHeaderCell }
    ],
    formatData: (data, selectedColumns) => {
      const tableData = data.map(row => {
        return filterColumns(selectedColumns,
          {
            id: { text: row.id, classNameProps: classes.tableBodyCell },
            tag: { text: row.name, classNameProps: classes.tableBodyCell },
            resourceId: { text: row.resource_id, classNameProps: classes.tableBodyCell },
            name: { text: row.resource_name, classNameProps: classes.tableBodyCell },
            type: { text: row.resource_type, classNameProps: classes.tableBodyCell }
          }
        );
      });

      return [getHeaderOrdered(tableData, selectedColumns), tableData];
    }
  };

  const systemExports = {
    type: "System",
    description: `${t("reports.systemDescription")}`,
    url: urlMap.systemLogs,
    fetchData: reportService.fetchSystemEvents,
    filters: ["dates", "users", "eventType"],
    sortMap: { timestamp: "created_at" },
    initialFilterValues: {
      start_date: formatDateAsYYYYMMDD(moment().subtract(1, "month")),
      end_date: formatDateAsYYYYMMDD(moment()),
      selectedUsers: [],
      eventType: [],
      orderBy: "timestamp",
      direction: "desc"
    },
    header: [
      { id: "timestamp", label: t("label.timestamp"), sortable: true, classNameProps: classes.tableHeaderCell },
      { id: "user", label: t("widgetField.user"), sortable: false, classNameProps: classes.tableHeaderCell },
      { id: "logType", label: t("label.logType"), sortable: false, classNameProps: classes.tableHeaderCell },
      { id: "source", label: t("reports.source"), sortable: false, classNameProps: classes.tableHeaderCell }
    ],
    formatData: (data, selectedColumns) => {
      const tableData = data.map(row => {
        return filterColumns(selectedColumns,
          {
            timestamp: { text: formatParseDate(row.created_at), classNameProps: classes.tableHeaderCell },
            user: { text: typeof row.user === "string" ? row.user : "", classNameProps: classes.tableBodyCell },
            logType: { text: row.description, classNameProps: classes.tableBodyCell },
            source: { text: row.source, classNameProps: classes.tableBodyCell }
          }
        );
      });

      return [getHeaderOrdered(tableData, selectedColumns), tableData];
    }
  };

  const presenceDetection = {
    type: "presenceDetection",
    description: `${t("reports.presenceDetectionDescription")}`,
    url: urlMap.presenceDetection,
    fetchData: reportService.fetchLocksFieldUpdates,
    filters: ["dates", "users", "lockStatus", "locks", "userTags", "lockTags"],
    initialFilterValues: {
      start_date: formatDateAsYYYYMMDD(moment().subtract(1, "month")),
      end_date: formatDateAsYYYYMMDD(moment()),
      selectedUsers: [],
      selectedLocks: [],
      selectedUserTags: [],
      selectedLockTags: [],
      orderBy: "",
      direction: "",
      lockStatus: 0
    },
    header: [
      { id: "timestamp", label: t("label.collectionTime"), sortable: false, classNameProps: classes.tableHeaderCell },
      { id: "user", label: t("widgetField.user"), sortable: false, classNameProps: classes.tableHeaderCell },
      { id: "email", label: t("form.email"), sortable: false, classNameProps: classes.tableHeaderCell },
      { id: "lockId", label: t("form.lockId"), sortable: false, classNameProps: classes.tableHeaderCell },
      { id: "lockName", label: t("label.lockName"), sortable: false, classNameProps: classes.tableHeaderCell },
      { id: "locked", label: t("label.locked"), sortable: false, classNameProps: classes.tableHeaderCell },
      { id: "latched", label: t("label.latched"), sortable: false, classNameProps: classes.tableHeaderCell },
      { id: "location", label: t("label.location"), sortable: false, classNameProps: classes.tableHeaderCell },
      { id: "rawData", label: t("label.rawData"), sortable: false, classNameProps: classes.tableHeaderCell }
    ],
    formatData: (data, selectedColumns) => {
      const tableData = data.map(row => {
        return filterColumns(selectedColumns,
          {
            timestamp: { text: formatParseDate(row.timestamp), classNameProps: classes.tableHeaderCell },
            user: { text: row.user ? `${row.user.first_name} ${row.user.last_name}` : "", classNameProps: classes.tableBodyCell },
            email: { text: row.user?.email, classNameProps: classes.tableBodyCell },
            lockId: { text: row.lock?.hardware_id, classNameProps: classes.tableBodyCell },
            lockName: { text: row.lock?.name, classNameProps: classes.tableBodyCell },
            locked: { text: row.locked ? "Yes" : "No", classNameProps: classes.tableBodyCell },
            latched: { text: row.latched ? "Yes" : "No", classNameProps: classes.tableBodyCell },
            location: { text: typeof row.location === "string" ? row.location : formatLocation(row.location), classNameProps: classes.tableBodyCell },
            rawData: { text: row.raw_data, classNameProps: classes.tableBodyCell }
          }
        );
      });

      return [getHeaderOrdered(tableData, selectedColumns), tableData];
    }
  };

  return [
    accessExports,
    keysExports,
    userExports,
    lockExports,
    tagExports,
    systemExports,
    ...sera4Mode ? [presenceDetection] : []
  ];
};

export const getReport = (reportUrl, currentMembership, sera4Mode, t, classes) => {
  const reports = getReportLabels(currentMembership, sera4Mode, t, classes);
  return reports.find(report => reportUrl === report.url) || reports[0];
};