const presenceDetectionWidgetStyle = theme => ({
  chip: {
    "fontSize": theme.lsyPalette.subtitle3,
    "maxHeight": "27px",
    "& .MuiChip-deleteIcon": {
      width: "20px"
    },
    "& .MuiChip-label": {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1)
    }
  },
  chipText: {
    fontSize: theme.lsyPalette.subtitle3,
    fontWeight: theme.lsyPalette.lightWeight,
    marginLeft: theme.spacing(0.5),
    maxWidth: "60px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap"
  },	
  chipIcon: {
    margin: "0",
    width: "15px",
    color: theme.lsyPalette.text
  },
  header: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    flexWrap: "wrap"
  },
  inlineFlex: {
    display: "inline-flex"
  },
  info: {
    fontSize: theme.lsyPalette.subtitle3
  },
  infoField: {
    color: theme.lsyPalette.primary.mainLight,
    marginRight: theme.spacing(1)
  },
  itemsSelected: {
    border: `1px solid ${theme.lsyPalette.border.lightGrey}`,
    overflow: "auto",
    height: "250px",
    borderRadius: theme.shape.borderRadius
  },
  location: {
    marginLeft: theme.spacing(1)
  },
  marginTop: {
    [theme.breakpoints.up("md")]: {
      marginTop: theme.spacing(10)
    }
  },
  modalContent: {
    height: "350px"
  },
  pagination: {
    "display": "flex",
    "alignItems": "center",
    "width": "fit-content",
    "& div": {
      minHeight: "auto"
    },
    "& p": {
      fontSize: theme.lsyPalette.subtitle3
    },
    "& button": {
      "fontSize": theme.lsyPalette.subtitle3,
      "padding": theme.spacing(0.25),
      "& svg": {
        width: "16px",
        height: "16px"
      }
    },
    "& .MuiSelect-select": {
      fontSize: theme.lsyPalette.subtitle3,
      display: "flex",
      alignItems: "baseline",
      margin: 0,
      padding: "0"
    },
    "& svg": {
      width: "0.75em",
      height: "0.75em",
      top: "0px"
    },
    "& .MuiToolbar-root": {
      padding: 0
    }
  },
  redirectIcon:{
    width: "20px",
    height: "20px"
  },
  redirectLink: {
    color: theme.lsyPalette.link,
    textDecoration: "none",
    display: "flex",
    alignItems: "center",
    width: "fit-content"
  },
  rtsContainer: {
    marginTop: theme.spacing(2)
  },
  searchContainer: {
    maxWidth: "100px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    fontWeight: theme.typography.fontWeight
  },
  selectStatus: {
    "fontSize": theme.lsyPalette.subtitle3,
    "fontWeight": theme.lsyPalette.lightWeight,
    "backgroundColor": theme.lsyPalette.secondary.background,
    "borderRadius": theme.shape.borderRadius,
    "& .MuiOutlinedInput-notchedOutline": {
      border: "0"
    },
    "& .MuiSelect-select": {
      paddingTop: "5px",
      paddingBottom: "4.75px",
      paddingLeft: theme.spacing(1.5)
    }
  },
  selectOptions: {
    fontSize: theme.lsyPalette.subtitle3
  },
  spaceAround: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  tableHeaderCell: {
    ...theme.tableCell,
    fontSize: theme.lsyPalette.subtitle3,
    whiteSpace: "nowrap"
  },
  tableBodyCell: {
    ...theme.tableCell,
    fontSize: theme.lsyPalette.subtitle3
  },
  timelineHeader: {
    maxWidth: "100%",
    whiteSpace: "break-spaces",
    textOverflow: "ellipsis",
    overflow: "hidden",
    fontSize: theme.lsyPalette.body1
  },
  timelineContent: {
    fontSize: theme.lsyPalette.subtitle3
  }
});

export default presenceDetectionWidgetStyle;