export const WORK_SESSION_STATUS = Object.freeze({
  0: "workSession.status.opened",
  1: "workSession.status.closed"
});

export const WORK_SESSION_CONFIRMATION_STATUS = Object.freeze({
  0: "workSession.status.started",
  1: "workSession.status.complete",
  2: "workSession.status.pending",
  3: "workSession.status.approved",
  4: "workSession.status.declined",
  5: "workSession.status.expired"
});