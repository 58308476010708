import { useEffect, useReducer } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";

import { Grid, Skeleton } from "@mui/material";

import Card from "components/Card/Card";
import DataTable from "_components/Table/DataTable";
import ToggleSwitch from "_components/ToggleSwitch/ToggleSwitch";

import { tableReducer } from "_reducers/table.reducer";
import { organizationService } from "_services";
import { fetchErrorSymbols, fetchStatus } from "_utils";

import { makeStyles } from "tss-react/mui";
import styles from "assets/jss/containers/webhooks/webhooksSelectorStyle";

const useStyles = makeStyles()(styles);

export default function WebhooksSelector({ webhookOptions, canUpdate }) {
  const { classes } = useStyles();
  const { t } = useTranslation("default");

  const { setError } = useForm({
    mode: "onChange",
    reValidateMode: "onChange"
  });

  const [enabledWebhooks, setEnabledWebhooks] = useReducer(tableReducer, [
    {
      id: (
        <Skeleton variant="text" className={classes.dataPlaceHolder}></Skeleton>
      ),
      label: (
        <Skeleton variant="text" className={classes.dataPlaceHolder}></Skeleton>
      ),
      enabled: false
    }
  ]);

  const loadEnabledWebhooks = async () => {
    try {
      const enabledWhs = webhookOptions?.enabled_webhooks || [];
      const models = [
        { id: 0,  label: t("features.accessRequests"),    model: "AccessRequest",  enabled: enabledWhs.includes("AccessRequest") },
        { id: 1,  label: t("label.key.individual"),       model: "Key",            enabled: enabledWhs.includes("Key") },
        { id: 2,  label: t("label.key.group"),            model: "LockGroup::Key", enabled: enabledWhs.includes("LockGroup::Key") },
        { id: 3,  label: t("label.key.site"),             model: "Site::Key",      enabled: enabledWhs.includes("Site::Key") },
        { id: 4,  label: t("features.locks"),             model: "Lock",           enabled: enabledWhs.includes("Lock") },
        { id: 5,  label: t("label.lockEvent"),            model: "LockEvent",      enabled: enabledWhs.includes("LockEvent") },
        { id: 6,  label: t("label.lockNotes"),            model: "NotePhoto",      enabled: enabledWhs.includes("NotePhoto") },
        { id: 7,  label: t("label.lockStatusUpdates"),    model: "LockStatus",     enabled: enabledWhs.includes("LockStatus") },
        { id: 8,  label: t("label.organizationUpdates"),  model: "Organization",   enabled: enabledWhs.includes("Organization") },
        { id: 9,  label: t("features.lockCollections"),   model: "SiteCollection", enabled: enabledWhs.includes("SiteCollection") },
        { id: 10, label: t("features.systemLogs"),        model: "SystemLog",      enabled: enabledWhs.includes("SystemLog") },
        { id: 11, label: t("label.user"),                 model: "User",           enabled: enabledWhs.includes("User") },
        { id: 12, label: t("label.workSession"),          model: "WorkSession",    enabled: enabledWhs.includes("WorkSession") },
        { id: 13, label: t("label.resourceNote"),         model: "Resource::Note", enabled: enabledWhs.includes("Resource::Note") }
      ];
      
      setEnabledWebhooks({ action: "replace", data: models });
    } catch (e) {
      setEnabledWebhooks({ action: "clear" });
    }
  };
  
  useEffect(() => {
    loadEnabledWebhooks();
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [webhookOptions]);

  const disableSpecificWebhook = async (id, checked) => {
    try {
      const enabledWhs = enabledWebhooks.filter(model => model.id === id ? checked : model.enabled).map(model => model.model);

      await organizationService.updateOrganizationWebhooks({
        bodyParams: { options: { enabled_webhooks: enabledWhs } }
      });

      setEnabledWebhooks({ action: "update", index: id, targetColumn: "enabled", newValue: checked });

    } catch (e) {
      const errors = fetchErrorSymbols(e);
      if (fetchStatus(e) === 422) {
        setError("options.enabled_webhooks", {
          type: "manual",
          message: t(errors[0])
        });
      }
      console.warn(errors);
    }
  };

  const toogleAction = (row) => { 
    return (
      <Grid container className={classes.verticalAlign}>
        <ToggleSwitch
          className={classes.border}
          checked={row.enabled}
          handleChange={(checked) => disableSpecificWebhook(row.id, checked)}
          disabled={!canUpdate}
        />
      </Grid>
    );
  };

  return (
    <Card className={classes.card}>
      <DataTable
        data={enabledWebhooks}
        striped
        height={100}
        columns={[
          {
            name: t("widget.webhookWatch"),
            selector: (row) => row.label
          },
          {
            name: "",
            selector: toogleAction
          }
        ]}
      />
    </Card>
  );
}

WebhooksSelector.propTypes = {
  webhookOptions: PropTypes.object,
  canUpdate: PropTypes.bool
};