import PropTypes from "prop-types";
import { Controller } from "react-hook-form";

import {
  FormControlLabel
} from "@mui/material";

import ToggleSwitch from "_components/ToggleSwitch/ToggleSwitch";

import { makeStyles } from "tss-react/mui";
import styles from "assets/jss/components/Switch/formSwitchStyle";

const useStyles = makeStyles()(styles);

const FormSwitch = (props) => {
  const { control, name, label, defaultValue } = props;
  const { classes } = useStyles();

  return (
    <FormControlLabel
      data-testid={`formSwitch-${name}`}
      label={label}
      labelPlacement="start"
      className={classes.switchLabel}
      disableTypography={true}
      control={<Controller
        render={({ field }) => (
          <ToggleSwitch
            checked={field.value}
            handleChange={(checked) => field.onChange(checked)}
          />
        )}
        name={name}
        control={control}
        defaultValue={defaultValue}
      />}
    />
  );
};

FormSwitch.propTypes = {
  control: PropTypes.object.isRequired,
  defaultValue: PropTypes.bool,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired
};

export default FormSwitch;