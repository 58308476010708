import { get, put, post } from "_helpers/axios/gw.client";
import { twsWebUrl } from "_services/helper";

const MAX_LOCKS_QUANTITY_GET = 50;

const fetchOpenLocks = async (options) => {
  options["lock_state"] = "open";
  options["with_user"] = true;
  return get(`${twsWebUrl}/locks/status`, options);
};

//0 = all, 1 = open, 2 = closed
const fetchLockLocations = async (options) => {
  return get(`${twsWebUrl}/locks/locations`, options);
};

const fetchLocks = async (options) => {
  options.include = "tags,licenses";

  if (!options.ids || options.ids.length <= MAX_LOCKS_QUANTITY_GET) {
    return get(`${twsWebUrl}/locks`, options);
  } else {
    return post(`${twsWebUrl}/locks/index`, options);
  }
};

const fetchLock = async (options) => {
  options.include = "tags,licenses";
  return get(`${twsWebUrl}/locks/${options.lockId}`, options);
};

const fetchLockStatus = async (options) => {
  return get(`${twsWebUrl}/locks/${options.lockId}/status`);
};

const fetchLockAccessHistory = async (options) => {
  return get(`${twsWebUrl}/locks/${options.lockId}/access_history`, options);
};

const fetchLockNotes = async (options) => {
  return get(`${twsWebUrl}/locks/${options.lockId}/notes`, options);
};

const fetchLockNotePhoto = async (options) => {
  return get(`${twsWebUrl}/buckets/lock_notes/image/${options.imageId}`, options);
};

// the hardware types used by a single organization (for active locks)
const fetchHardwareTypes = async () => {
  return get(`${twsWebUrl}/locks/hardware_types`);
};

const updateLock = async (options) => {
  const payload = {
    lock: { ...options.payload }
  };

  return await put(`${twsWebUrl}/locks/${options.lockId}`, payload);
};

const sendAccessCode = async (options) => {
  const payload = {
    ...options.payload
  };

  return await post(`${twsWebUrl}/locks/${options.lockId}/access_codes`, payload);
};

const fetchFieldUpdates = async (lockId, payload) => {
  return get(`${twsWebUrl}/locks/${lockId}/field_updates`, payload);
};

const deactivateLock = async (lockId, options) => {
  return await post(`${twsWebUrl}/locks/${lockId}/deactivate`, options);
};

const reactivateLock = async (lockId, options) => {
  return await post(`${twsWebUrl}/locks/${lockId}/reactivate`, options);
};

export const lockService = {
  deactivateLock,
  fetchLock,
  fetchOpenLocks,
  fetchLockLocations,
  fetchLocks,
  fetchLockStatus,
  fetchLockAccessHistory,
  fetchLockNotes,
  fetchLockNotePhoto,
  fetchHardwareTypes,
  fetchFieldUpdates,
  reactivateLock,
  sendAccessCode,
  updateLock
};