import baseStyles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import { tooltip } from "assets/jss/material-dashboard-pro-react.js";

const editOrganizationStyle = (theme) => ({
  ...baseStyles,
  tooltip,
  labelHorizontal: {
    ...baseStyles.labelHorizontal,
    textTransform: "capitalize"
  },
  optionsLabel: {
    paddingTop: "0px"
  },
  rowTitle: {
    fontWeight: "bold",
    display: "flex"
  },
  rowData: {
    textAlign: "right"
  },
  licenseMeta: {
    marginLeft: "5%"
  },
  inputs: {
    width: "100%"
  },
  cryptoRowHeader: {
    backgroundColor: "#dddddd"
  },
  cryptoRowData: {
    "border": "1px solid #dddddd",
    "textAligh": "left",
    "padding": "8px",
    "&:nth-of-type(even)": {
      backgroundColor: "#dddddd"
    }
  },
  fieldBox: {
    marginBottom: "25px"
  },
  marginTop: {
    marginTop: theme.spacing(2)
  },
  itemGrid: {
    width: "100%"
  },
  itemBox: {
    width: "100%",
    marginBottom: "15px",
    marginTop: "15px"
  },
  itemBoxSelect: {
    width: "100%",
    marginBottom: "15px"
  },
  helpIcon: {
    fill: theme.lsyPalette.primary.mainLight,
    transform: "scale(0.7)"
  },
  switchLabel: {
    marginLeft: "0px"
  },
  card: {
    marginTop: "2em"
  },
  link: {
    "color": theme.lsyPalette.primary.main,
    "&:hover": {
      color: theme.lsyPalette.primary.main
    }
  },
  warningModal: {
    [theme.breakpoints.up("md")]:{
      maxWidth: "600px"
    }
  },
  marginBottom: {
    marginBottom: theme.spacing(2)
  }
});

export default editOrganizationStyle;