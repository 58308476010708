import { get, post } from "_helpers/axios/gw.client";
import { twsWebUrl } from "_services/helper";

const fetchReportsData = async (options) => {
  return await get(`${twsWebUrl}/reports`, options);
};

const fetchUsers = async (options) => {
  return await get(`${twsWebUrl}/reports/users`, options);
};

const fetchTags = async (options) => {
  return await get(`${twsWebUrl}/reports/tags`, options);
};

const fetchLocks = async (options) => {
  return await post(`${twsWebUrl}/reports/locks`, options);
};

const fetchLockAccess = async (options) => {
  return await post(`${twsWebUrl}/reports/locks/access_history`, options);
};

const fetchKeys = async (options) => {
  return await post(`${twsWebUrl}/reports/keys`, options);
};

const fetchSystemEvents = async (options) => {
  return await get(`${twsWebUrl}/reports/system_events`, options);
};

const fetchUsersChartData = async (options) => {
  return await get(`${twsWebUrl}/reports/users/stats`, options);
};

const fetchTagsChartData = async (options) => {
  return await get(`${twsWebUrl}/reports/tags/stats`, options);
};

const fetchLocksChartData = async (options) => {
  return await get(`${twsWebUrl}/reports/locks/stats`, options);
};

const fetchSystemEventsChartData = async (options) => {
  return await get(`${twsWebUrl}/reports/system_events/stats`, options);
};

const fetchLocksFieldUpdates = async (options) => {
  return await get(`${twsWebUrl}/reports/locks/field_updates`, options);
};

export const reportService = {
  fetchReportsData,

  fetchUsers,
  fetchLocks, 
  fetchLockAccess,
  fetchLocksFieldUpdates,
  fetchKeys,
  fetchTags, 
  fetchSystemEvents,

  fetchUsersChartData,
  fetchTagsChartData,
  fetchLocksChartData,
  fetchSystemEventsChartData
};