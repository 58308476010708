import { useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import {
  Chip,
  MenuItem,
  Select
} from "@mui/material";

import { GetApp } from "@mui/icons-material";

import styles from "assets/jss/components/Select/selectExportStyle";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(styles);

const EXPORT_FORMATS = Object.freeze({
  csv: { name: "CSV", value: "csv" },
  xlsx: { name: "XLSX", value: "xlsx" }
});

const SelectExport = (props) => {
  const { disabled, submit } = props;

  const { classes, cx } = useStyles();
  const { t } = useTranslation("default");
  const exportLabel = () => t("actions.export");

  const [open, setOpen] = useState(false);

  const handleOpenSelect = () => {
    if (disabled) {
      return;
    }

    setOpen(true);
  };

  const handleCloseSelect = () => setOpen(false);

  return <Chip
    label={
      <div className={classes.container}>
        <GetApp
          className={cx(classes.chipIcon, disabled ? "" : classes.clickable)}
          color={disabled ? "disabled" : "inherit"}
          onClick={handleOpenSelect}/>
        <Select
          data-testid="selectExport"
          value={exportLabel()}
          className={classes.select}
          onChange={submit}
          open={open}
          onClose={handleCloseSelect}
          onOpen={handleOpenSelect}
          disabled={disabled}
          renderValue={exportLabel}
          IconComponent={() => null}
          SelectDisplayProps={{ style: { paddingRight: 0 } }}
        >
          <MenuItem value={exportLabel()} className={classes.selectHiddenOption}/>
          {Object.values(EXPORT_FORMATS).map(format => (
            <MenuItem key={format.value} value={format.value} className={classes.selectOptions}>{format.name}</MenuItem>
          ))}
        </Select>
      </div>}
    data-testid="exportChip"
    className={classes.chip}
  />;
};

SelectExport.propTypes = {
  disabled: PropTypes.bool,
  submit: PropTypes.func.isRequired
};

export default SelectExport;