import * as actionTypes from "./actionTypes";
import { authService } from "../_services";
import { memberActions, modalActions } from "./";
import { tokenExpKey } from "../_helpers";
import { fetchErrors, fetchStatus } from "../_utils";
import { clearState, logout } from "_services/helper";
import { userService } from "_services/lockstasy/user.service";
import { localeActions, mobileActions } from "_actions";
import twsResourceFactory from "_resources";

function login(username, password, language, mfa_code, mfaSetup, piStep, tcAgreement) {
  function request(user) { return { type: actionTypes.AUTH_START, user }; }
  function success(user) { return { type: actionTypes.AUTH_SUCCESS, user, loginMethod: "password" }; }
  function failure(error) { return { type: actionTypes.AUTH_FAIL, error }; }

  return dispatch => {
    dispatch(request({ username }));
    authService.login(username, password, mfa_code, tcAgreement)
      .then(payload => {
        if (payload.terms && payload.terms.required) {
          dispatch({type: actionTypes.AUTH_TC_STEP, tcStep: true, piStep: true, success: "initial_tc_step"});
          return payload;
        } else if (payload.mfa_step_required) {
          dispatch({type: actionTypes.AUTH_MFA_STEP, mfaEnabled: true, success: "initial_mfa_step"});
          return payload;
        } else if (payload.setup_required) {

          let mfaSetupData = {
            qrcode_image: payload.qrcode_image,
            otpauth_url: payload.otpauth_url,
            base32: payload.base32
          };

          dispatch({type: actionTypes.AUTH_MFA_SETUP, mfaEnabled: true, mfaSetupData, success: "initial_mfa_step"});
          return payload;
        } else if (piStep) {
          dispatch({type: actionTypes.AUTH_MFA_RESET, mfaEnabled: false, mfaSetup: false});
          dispatch({type: actionTypes.AUTH_TC_STEP, tcStep: false, piStep: true, success: "initial_pi_step"});
          dispatch(modalActions.showModal({ modalProps: {
            open: mfaSetup,
            user: payload.tws_account,
            memberships: payload.tws_memberships
          }}));
          dispatch(mobileActions.setMobile(payload.tws_mobile));
          return payload.tws_memberships;
        } else{
          const userLanguage = payload.tws_account.language;
          // update their default language, as they've intentionally changed it
          if (language) {
            if (userLanguage != language) {
              try {
                // doesn't matter WHICH membership is used; we just need a good one
                const membership = payload.tws_memberships.find(({ status }) => status > 0);

                if (membership)
                  userService.updateUser({ membershipId: membership.id, payload: { language: language }});
              } catch(e) {
                // throw these away, errors updating a language are not important and should not disrupt login
                console.debug("Error updating language", e);
              }
            }
          } else {
            // use their default language and set it locally
            console.debug("Auto-setting the locale to: ", userLanguage);
            dispatch(localeActions.setLocale({ language: userLanguage }));
          }

          if (mfaSetup) {
            dispatch(modalActions.showModal({ modalProps: {
              open: true,
              user: payload.tws_account,
              memberships: payload.tws_memberships
            }}));
          } else {
            dispatch({type: actionTypes.AUTH_MFA_RESET, mfaEnabled: false, mfaSetup: false});
            dispatch(success(payload.tws_account));
          }
          
          dispatch(mobileActions.setMobile(payload.tws_mobile));
          return payload.tws_memberships;
        }
      })
      .then(data => {
        if (!data.mfa_step_required && !data.setup_required && !data.terms?.required && !piStep){
          dispatch(memberActions.setMemberships(data));
        }
      })
      .catch(error => {
        let failMsg = "";
        let errors = fetchErrors(error);

        if (fetchStatus(error) > 400) {
          // Invalid login
          if (errors?.session) {
            failMsg = errors.session?.msg;
          }
          // Account locked or invalid mfa code
          else if(errors?.mfa) {
            failMsg = errors.mfa?.msg;
          }
          else if(errors?.account) {
            failMsg =  errors.account.msg;
          }
          // Something went wrong here, network error
          else {
            failMsg = "network_error";
          }
        } else {
          /* network error occurs when gateway or other crashes */
          failMsg = "network_error";
        }
        dispatch(failure(failMsg || error.toString()));
      });
  };
}

function start() {
  return dispatch => {
    dispatch({ type: actionTypes.AUTH_START });
  };
}

function success(user, memberships, loginMethod) {
  return dispatch => {
    dispatch(memberActions.setMemberships(memberships));
    dispatch(modalActions.hideModal());
    dispatch({ type: actionTypes.AUTH_SUCCESS, user, loginMethod: loginMethod });
  };
}

function resetPassword(username) {
  function request() { return { type: actionTypes.PASS_RESET_START }; }
  function success(success) { return { type: actionTypes.PASS_RESET_SUCCESS, success }; }
  function failure(error) { return { type: actionTypes.PASS_RESET_FAIL, error }; }
  
  return dispatch => {
    dispatch(request());

    authService.resetPassword(username)
      .then(() => {
        dispatch(success("reset_success"));
      })
      .catch(error => {
        let failMsg, errors = fetchErrors(error);
        if (errors.account) {
          failMsg = errors.account.msg;
        } else if (fetchStatus(error) > 400) {
          failMsg = "reset_failure";
        } else {
          /* network error occurs when gateway or other crashes */
          failMsg = "network_error";
        }

        dispatch(failure(failMsg));
      });
  };
}

export const logoutAction = (softLogout = false) => {
  twsResourceFactory.flushResources();
  memberActions.removeCurrentMembership();
  logout(softLogout);
  return { type: actionTypes.AUTH_LOGOUT };
};

function resetMfa(){
  return dispatch => {
    dispatch({type: actionTypes.AUTH_MFA_RESET, mfaEnabled: false, mfaSetup: false});
  };
}


export const authCheckToken = () => {
  return dispatch => {
    const tokenDate = localStorage.getItem(tokenExpKey);

    if (tokenDate && (tokenDate <= (new Date()).getTime())) {
      dispatch(logoutAction(true));
    }
  };
};

export const authClearLocalState = () => {
  return dispatch => {
    clearState();

    // initial a auth fail, wherein it will clear the full state of the user record/redux
    const failAction = { type: actionTypes.AUTH_FAIL };
    dispatch(() => failAction);
  };
};

function userUpdate(data) {
  const localUser = localStorage.getItem("user");
  var user = JSON.parse(localUser);
  user = { ...user, ...data };
  localStorage.setItem("user", JSON.stringify(user));

  return { type: actionTypes.AUTH_UPDATE, data: { user: user } };
}

const setSera4Mode = mode => {
  return dispatch => dispatch({ type: actionTypes.SERA4_MODE, sera4Mode: mode });
};

export const authActions = {
  login,
  logout: logoutAction,
  resetPassword,
  resetMfa,
  authCheckToken,
  logoutAction,
  authClearLocalState,
  userUpdate,
  success,
  start,
  setSera4Mode
};
