const webhooksSelectorStyle = theme => ({
  card: {
    marginTop: theme.spacing(3),
    height: "fit-content"
  },
  dataPlaceHolder: {
    width: "6vw"
  },
  verticalAlign: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  border: {
    borderRadius: "0px !important"
  }
});

export default webhooksSelectorStyle;